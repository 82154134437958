import c1 from "../../img/service/1.png";
import c2 from "../../img/service/2.png";
import c3 from "../../img/service/3.png";
import c4 from "../../img/service/4.png";
import c5 from "../../img/service/5.png";
import c6 from "../../img/service/6.png";

const course = [
  {
    id: "1",
    img: `${c1}`,
    name: "Wordpress Course",
    desc: "In this course you will learn how to make a website using WordPress.",
    author: "A course by Waleed Shaikh: Software Expert & Trainer",
    learn: [
      { item: "Hosting & Domain setup" },
      { item: "Wordpress Installation" },
      { item: "Wordpress basic setup" },
      { item: "Install themes" },
      { item: "How to build a page using elementor" },
      { item: "Design 3-4 page website" },
    ],
  },
  {
    id: "2",
    img: `${c6}`,
    name: "Digital Marketing",
    desc: "In this course you will learn about Digital Marketing and how to Optimize websites to rank higher in search results.",
    author: "A course by Ashir Ansari: Digital Marketing Expert & Trainer",
    learn: [
      { item: "Search Engine Optimization (SEO)" },
      { item: "Content Marketing" },
      { item: "Social Media Marketing" },
      { item: "Email Marketing" },
      { item: "Pay-Per-Click Advertising (PPC)" },
      { item: "Analytics and Data Analysis" },
    ],
  },
  {
    id: "3",
    img: `${c2}`,
    name: "Basics of Html",
    desc: "In this course you will learn about html basics and how to design a website structure.",
    author: "A course by Ashir Ansari: Digital Marketing Expert & Trainer",
    learn: [
      { item: "Install VS Code" },
      { item: "Learn Html Tags" },
      { item: "Learn how to make forms" },
      { item: "Learn Basic Website Structure" },
      { item: "Learn Html Meta Tags" },
      { item: "Learn How to do Seo" },
    ],
  },
  {
    id: "4",
    img: `${c3}`,
    name: "Css Basics",
    desc: "In this course you will learn about css basics and how to make website attractive.",
    author: "A course by Ashir Ansari: Digital Marketing Expert & Trainer",
    learn: [
            { item: "Why is CSS important?" },
            { item: "Learn CSS selectors" },
            { item: "Learn Box Model" },
            { item: "Learn Typography" },
            { item: "Learn Color and background" },
            { item: "Learn Layout and positioning" },
            { item: "Learn Media queries" }],
  },
  {
    id: "5",
    img: `${c4}`,
    name: "Javascript Course",
    desc: "In this course you will learn about Javascript concepts & how to make website react.",
    author: "A course by Ashir Ansari: Digital Marketing Expert & Trainer",
    learn: [
      { item: "Hosting & Domain setup" },
      { item: "Wordpress Installation" },
      { item: "Wordpress Installation" },
      { item: "Wordpress basic setup" },
      { item: "Install themes" },
      { item: "How to build a page using elementor" },
      { item: "Design 34 page website" },
    ],
  },
  {
    id: "6",
    img: `${c1}`,
    name: "Wordpress Ecommerce",
    desc: "In this course you will learn to make an ecommerce website using wordpress.",
    author: "A course by Ashir Ansari: Digital Marketing Expert & Trainer",
    learn: [
      { item: "Hosting & Domain setup" },
      { item: "Wordpress Installation" },
      { item: "Wordpress basic setup" },
      { item: "Install themes" },
      { item: "Install plugins" },
      { item: "Create all pages using elementor" },
      { item: "Learn Woocommerce" },
      { item: "Payment Integration" },
      { item: "Complete website" },
    ],
  },
  {
    id: "7",
    img: `${c5}`,
    name: "Excel Course",
    desc: "In this course you will learn basic concepts of excel and how to make sheets.",
    author: "A course by Ashir Ansari: Digital Marketing Expert & Trainer",
    learn: [
      { item: "Introduction to Excel" },
      { item: "Basic Formulas and Functions" },
      { item: "Cell Formatting" },
      { item: "Working with Rows and Columns" },
      { item: "Managing Worksheets and Workbooks" },
      { item: "Excel Tips and Tricks" },
    ],
  },
];

export default course;
