import React from "react";
import "./Philosophy.css";
import IMAGES from "../img";
import { useNavigate } from "react-router-dom";

const Philosophy = () => {
  const navigate = useNavigate();
  return (
    <div className="p-container container text-center">
      <h1 style={{ fontWeight: "600" }} className="mb-5">
        What is <span>The Elites Hub?</span>
      </h1>
      <p>
        The Elites Hub is a global community where like-minded individuals are
        striving to become better.
      </p>
      <p>
        Every member receives advanced training and mentoring from our team of
        millionaire industry specialists.
      </p>
      <div className="p-container-card">
        <div>
          <h2>Step by Step Blueprint</h2>
          <ul>
            <li>Master the skills you need to maximize your income</li>
            <li>Hyper advanced learning application </li>
            <li>Easy-to-follow program</li>
          </ul>
        </div>
        <img src={IMAGES.p1} alt="" />
      </div>
      <div className="p-container-card p-img2">
        <div>
          <h2>Private Network & Community</h2>
          <ul>
            <li>Network with people on the same mission</li>
            <li>Learn from real world results and examples</li>
            <li>Make like-minded friends on your financial journey</li>
            <li>Celebrate your wins with people who will understand</li>
          </ul>
        </div>
        <img src={IMAGES.p2} alt="" />
      </div>
      <div className="p-container-card p-img2">
        <div>
          <h2>Get mentored every step</h2>
          <ul>
            <li>Professors will lay out a plan that works for YOU</li>
            <li>You’ll be mentored every step of your journey</li>
            <li>1-on-1 communication and advice from industry expert</li>
          </ul>
        </div>
        <img src={IMAGES.p3} alt="" />
      </div>
      <button className="rounded rounded-pill" onClick={()=>window.location.href = '/register'}>Join Now</button>
    </div>
  );
};

export default Philosophy;
