import React from "react";
import Header from "../components/Header/Header";
import "./Home.css";
import HeroSection from "../components/Home/HeroSection";
import HowItWorks from "../components/Home/HowItWorks";
import WhyUs from "../components/Home/WhyUs";
import OtherSay from "../components/Home/OtherSay";
import BecomeAffiliate from "../components/Home/BecomeAffiliate";
import SiteInsights from "../components/Home/SiteInsights";
import OurReviews from "../components/Home/OurReviews";
import CourseSlider from "../components/Home/CourseSlider";
import Faq from "../components/Home/Faq";
import ChooseBetter from "../components/Home/ChooseBetter";
import ControlMoney from "../components/Home/ControlMoney";
import FingerTips from "../components/Home/FingerTips";
import HappyStudents from "../components/Home/HappyStudents";
import PopularTopics from "../components/Home/PopularTopics";
import Philosophy from "./Philosophy";
import ActNow from "./ActNow";
import HeroSectionTwo from "../components/Home/HeroSectionTwo";

const Home = () => {
  return (
    <Header>
      {/* <HeroSection /> */}
      <HeroSectionTwo />
      <ChooseBetter />
      <Philosophy />
      <HappyStudents />
      <HowItWorks />
      <ActNow />
      <Faq />
      {/* <ControlMoney /> */}
      {/* <FingerTips /> */}
      {/* <PopularTopics /> */}
      {/* <SiteInsights /> */}
      {/* <BecomeAffiliate /> */}
      {/* <OurReviews /> */}
      {/* <CourseSlider /> */}
      {/* <WhyUs /> */}
      {/* <OtherSay /> */}
    </Header>
  );
};

export default Home;
