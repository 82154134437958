import React from "react";
import "./ActNow.css";
import { useNavigate } from "react-router-dom";

const ActNow = () => {
  const navigate = useNavigate();
  return (
    <div className="act-now-container ">
      <h1 className="mb-5">
        The Choice Is <span>Yours</span>
      </h1>
      <p>The Matrix wants you poor, weak, and alone.</p>
      <p>
        We have opened up a mass portal to give you the tools to escape
        modern-day slavery.
      </p>
      <p>
        <b>Do you wish to Escape?</b>
      </p>
      {/* <div className="text-center">
        <iframe
          style={{ borderRadius: "20px", margin: "30px 0" }}
          width="600"
          height="350"
          src="https://www.youtube.com/embed/wEz8M1fmFTY?si=ryQIDjbr1LQ60yh2"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div> */}
      <button
        className="rounded rounded-pill"
        onClick={() => (window.location.href = "/register")}
      >
        Join Now
      </button>
    </div>
  );
};

export default ActNow;
