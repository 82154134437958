import PlayCircleIcon from "@mui/icons-material/PlayCircle";

const CourseMenu = [
  {
    name: "Introduction",
    path: "/",
    icon: PlayCircleIcon,
  },
  {
    name: "Html Tags",
    path: "/",
    icon: PlayCircleIcon,
  },
  {
    name: "Html Points",
    path: "/",
    icon: PlayCircleIcon,
  },
];

export default CourseMenu;
