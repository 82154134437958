import React from "react";
import "./CourseDetail.css";
import Header from "../components/Header/Header";
import { useNavigate, useParams } from "react-router-dom";
import Faq from "../components/Home/Faq";
import course from "../components/Course/courses";

const CourseDetail = () => {
  const params = useParams();
  const navigate = useNavigate();
  const FilterCourse = course.filter((item) => item.id === params.id);
  console.log(FilterCourse);

  return (
    <Header>
      <div className="course-detail-container text-center">
        <div className="course-content">
          <div className="course-heading ">
            {FilterCourse && FilterCourse[0].name}
          </div>
          <p>{FilterCourse && FilterCourse[0].author}</p>
          <img width="100%" src={FilterCourse && FilterCourse[0].img} alt="" />
        </div>
        <div className="course-details text-start">
          <h6>What you will Learn?</h6>
          <ul>
            {FilterCourse[0]?.learn?.map((item, index) => {
              return <li>- {item.item}</li>;
            })}
          </ul>
          <h6>Course Description</h6>
          <p className="text-muted">
            <small>{FilterCourse && FilterCourse[0].desc}</small>
          </p>
          <button
            onClick={() => navigate("/register")}
            className="view-course-btn"
          >
            Enroll Now
          </button>
        </div>
      </div>

      <Faq />
    </Header>
  );
};

export default CourseDetail;
