import React, { useEffect, useState } from "react";
import Header from "../components/Header/Header";
import "./Register.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import VpnKeyIcon from "@mui/icons-material/VpnKey";

const ForgotPass = () => {
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(1);
  const [email, setEmail] = useState(null);
  const [otp, setOtp] = useState(null);
  const [password, setPassword] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [resendOtp, setResendOtp] = useState(false);
  const [seconds, setSeconds] = useState(30);

  const generateOTP = () => {
    const digits = "0123456789";
    let OTP = "";
    for (let i = 0; i < 6; i++) {
      OTP += digits[Math.floor(Math.random() * 10)];
    }
    return setOtp(OTP);
  };

  const startCountdown = () => {
    setResendOtp(false);
    setSeconds(30);
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds > 0) {
          return prevSeconds - 1;
        } else {
          clearInterval(interval);
          setResendOtp(true);
          return 0;
        }
      });
    }, 1000);
  };

  //! SEND OTP
  const sendOTP = (e, value) => {
    if (value === "resendOTP") {
      setResendOtp(false);
    }
    generateOTP();
    setMessage(null);
    e.preventDefault();
    setLoading(true);
    axios({
      method: "POST",
      data: {
        email: email,
        otp: otp,
      },
      withCredentials: true,
      url: `/api/user/sendOTP`,
    })
      .then((res) => {
        setTimeout(() => {
          setLoading(false);
          setMessage(null);
          setToggle(2);
          startCountdown();
        }, 1500);
      })
      .catch((err) => {
        setMessage(err.response.data.message);
        setLoading(false);
        console.log(err);
      });
  };

  //! OTP verification
  const otpVerification = (e) => {
    setMessage(null);
    e.preventDefault();
    setLoading(true);
    axios({
      method: "POST",
      data: {
        email: email,
        otp: otp,
      },
      withCredentials: true,
      url: `/api/user/otp-verification`,
    })
      .then((res) => {
        setTimeout(() => {
          setLoading(false);
          setMessage(null);
          setToggle(3);
        }, 1500);
      })
      .catch((err) => {
        if (err.response.data.error === "otp-expired") {
          setResendOtp(true);
        }
        setMessage(err.response.data.message);
        setLoading(false);
        console.log(err);
      });
  };

  //! UPDATE Password
  const updatePassword = (e) => {
    setMessage(null);
    e.preventDefault();
    setLoading(true);
    axios({
      method: "POST",
      data: {
        email: email,
        password: password,
      },
      withCredentials: true,
      url: `/api/user/update-password`,
    })
      .then((res) => {
        setTimeout(() => {
          setLoading(false);
          setMessage(null);
          navigate("/login");
        }, 1500);
      })
      .catch((err) => {
        setMessage(err.response.data.message);
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    generateOTP();
    //eslint-disable-next-line
  }, []);
  return (
    <Header>
      <div className="container-fluid bg-light register-form-container">
        {/* ============== SEND OTP ============ */}
        {toggle === 1 && (
          <div className="form login-form border shadow">
            <div className="row">
              <div className="register-img col-12 col-sm-12 col-md-4 col-lg-4">
                <div className="bg-dark text-white rounded-pill p-3">
                  <VpnKeyIcon className="text-back" />
                </div>
                <div>
                  <p className="text-dark fs-3 fw-bold m-0 mt-3">
                    Forgot Password
                  </p>
                </div>
              </div>
              <div className="login-form-fields-container col-12 col-sm-12 col-md-8 col-lg-8">
                <form onSubmit={sendOTP}>
                  <h5 className="mb-4 position-relative line2">
                    Forgot your password? Don't worry
                  </h5>
                  {/* email  */}
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Email address
                    </label>
                    <input
                      name="email"
                      type="email"
                      className="form-control"
                      placeholder="Enter your email here"
                      aria-describedby="emailHelp"
                      required
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-auto mb-3">
                    <span
                      id="passwordHelpInline"
                      className="form-text text-danger"
                    >
                      {message}
                    </span>
                  </div>
                  <button type="submit" className="reg-btn">
                    {loading ? (
                      <div className="d-flex align-items-center justify-content-center">
                        <span
                          className="spinner-grow spinner-grow-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="ms-2">Loading...</span>
                      </div>
                    ) : (
                      "Send OTP"
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        )}

        {/* ============== ENTER OTP ============ */}
        {toggle === 2 && (
          <div className="form login-form border shadow">
            <div className="row">
              <div className="register-img col-12 col-sm-12 col-md-4 col-lg-4">
                <div className="bg-dark text-white rounded-pill p-3">
                  <VpnKeyIcon className="text-back" />
                </div>
                <div>
                  <p className="text-dark fs-3 fw-bold m-0 mt-3">Confirm OTP</p>
                </div>
              </div>
              <div className="login-form-fields-container col-12 col-sm-12 col-md-8 col-lg-8">
                <form onSubmit={otpVerification}>
                  <h5 className="mb-4 position-relative line2">Confirm Otp</h5>
                  {/* email  */}
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label text-success"
                    >
                      OTP Sent Successfully to{" "}
                      <span className="text-primary">{email}</span>
                    </label>
                    <input
                      name="otp"
                      type="number"
                      className="form-control"
                      placeholder="Enter 6-digit OTP here"
                      aria-describedby="emailHelp"
                      required
                      maxLength={6}
                      onChange={(e) => {
                        setOtp(e.target.value);
                        setMessage(null);
                      }}
                    />
                  </div>
                  <div className="col-auto mb-3">
                    <span
                      id="passwordHelpInline"
                      className="form-text text-danger"
                    >
                      {message}
                    </span>
                  </div>
                  <button type="submit" className="reg-btn mb-3">
                    {loading ? (
                      <div className="d-flex align-items-center justify-content-center">
                        <span
                          className="spinner-grow spinner-grow-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="ms-2">Loading...</span>
                      </div>
                    ) : (
                      "Confirm"
                    )}
                  </button>
                </form>
                <form>
                  {resendOtp ? (
                    <div className="col-auto mb-3">
                      <span
                        id="passwordHelpInline"
                        className="form-text text-primary"
                      >
                        <span
                          onClick={(e) => {
                            sendOTP(e, "resendOTP");
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Resend OTP
                        </span>
                      </span>
                    </div>
                  ) : (
                    <div className="col-auto mb-3">
                      <span
                        id="passwordHelpInline"
                        className="form-text text-secondary"
                      >
                        <span style={{ fontSize: "13px" }}>
                          Resend after {seconds} seconds
                        </span>
                      </span>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        )}

        {/* ============== UPDATE PASSWORD ============ */}
        {toggle === 3 && (
          <div className="form login-form border shadow">
            <div className="row">
              <div className="register-img col-12 col-sm-12 col-md-4 col-lg-4">
                <div className="bg-dark text-white rounded-pill p-3">
                  <VpnKeyIcon className="text-back" />
                </div>
                <div>
                  <p className="text-dark fs-3 fw-bold m-0 mt-3">
                    Setup new password
                  </p>
                </div>
              </div>
              <div className="login-form-fields-container col-12 col-sm-12 col-md-8 col-lg-8">
                <form onSubmit={updatePassword}>
                  <h5 className="mb-4 position-relative line2">Hurray!</h5>
                  {/* Password  */}
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Password
                    </label>
                    <input
                      type="password"
                      name="password"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Enter password"
                      aria-describedby="emailHelp"
                      required
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                  </div>{" "}
                  {/* Confirm Pass  */}
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Confirm Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Confirm password"
                      aria-describedby="emailHelp"
                      required
                    />
                  </div>
                  <div className="col-auto mb-3">
                    <span
                      id="passwordHelpInline"
                      className="form-text text-danger"
                    >
                      <span>{message}</span>
                    </span>
                  </div>
                  <button type="submit" className="reg-btn">
                    {loading ? (
                      <div className="d-flex align-items-center justify-content-center">
                        <span
                          className="spinner-grow spinner-grow-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="ms-2">Loading...</span>
                      </div>
                    ) : (
                      "Update"
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </Header>
  );
};

export default ForgotPass;
