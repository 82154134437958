import axios from "axios";
import React, { useEffect, useState } from "react";

const WithdrawHistory = ({ heading, value, img, id }) => {
  const [loading, setLoading] = useState(false);
  const [finalData, setFinalData] = useState(null);
  const [totalWithdrawals, setTotalWithdrawals] = useState(0);

  const getAllWithDrawals = () => {
    setLoading(true);
    axios({
      method: "POST",
      data: {
        id: id,
      },
      withCredentials: true,
      url: `api/withdraw/get-all-withdrawals`,
    })
      .then((res) => {
        if (res.data.success) {
          setTimeout(() => {
            setLoading(false);
            const data = res.data.data.reverse();

            const totalAmount = data
              .filter((item) => item.status === "3")
              .reduce((sum, item) => sum + parseFloat(item.amount), 0);
            setTotalWithdrawals(totalAmount);

            if (value === "1") {
              const newData = data?.filter((data) => {
                return data?.status === "1" || data?.status === "2";
              });
              setFinalData(newData);
            }
            if (value === "2") {
              const newData = data?.filter((data) => {
                return data?.status === "3" || data?.status === "4";
              });
              setFinalData(newData);
            }
            if (value === "3") {
              setFinalData(data);
            }
          }, 1500);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getAllWithDrawals();
    // eslint-disable-next-line
  }, []);

  const getStatus = (elm) => {
    if (elm === "1") {
      return "Pending";
    } else if (elm === "2") {
      return "In Progress...";
    } else if (elm === "3") {
      return "Successfull";
    }
    if (elm === "4") {
      return "On Hold";
    }
  };

  return (
    <div className="tab">
      <div className="welcome-container">
        <div className="username d-flex justify-content-between">
          <span>
            <b>{heading}</b>
          </span>
          <span>
            <b>{totalWithdrawals}</b>
          </span>
        </div>
      </div>
      {!loading ? (
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Date</th>
              <th>Mode</th>
              {/* <th>Txn. No.</th> */}
              <th>Status</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {finalData?.map((data, index) => {
              return (
                <>
                  <tr>
                    <td data-label="#">
                      <strong>{index + 1}</strong>
                    </td>
                    <td data-label="Date">
                      {new Date(data?.createdAt).toLocaleDateString("en-US", {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      })}
                    </td>
                    <td data-label="Mode">{data?.mode}</td>

                    {(data?.status === "1" || data?.status === "2") &&
                      value === "1" && (
                        <td
                          data-label="Status"
                          className={`${
                            data?.status === "1"
                              ? "text-danger"
                              : "text-warning"
                          }`}
                        >
                          {getStatus(data?.status)}
                        </td>
                      )}

                    {(data?.status === "3" || data?.status === "4") &&
                      value === "2" && (
                        <td
                          data-label="Status"
                          className={`${
                            data?.status === "3"
                              ? "text-success"
                              : "text-danger"
                          }`}
                        >
                          {getStatus(data?.status)}
                        </td>
                      )}

                    {value === "3" && (
                      <td
                        data-label="Status"
                        className={`${data?.status === "1" && "text-danger"} ${
                          data?.status === "2" && "text-warning"
                        }  ${data?.status === "3" && "text-success"}  ${
                          data?.status === "4" && "text-danger"
                        }`}
                      >
                        {getStatus(data?.status)}
                      </td>
                    )}

                    <td data-label="Coins">Rs. {data?.amount}</td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div className="d-flex align-items-center justify-content-center mt-4">
          <span
            className="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span className="ms-2">Loading...</span>
        </div>
      )}
    </div>
  );
};

export default WithdrawHistory;
