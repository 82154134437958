import React from "react";
import { Link } from "react-router-dom";
import "../Footer/Footer.css";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const Footer = () => {
  return (
    <div className="container-fluid text-white footer-container">
      <div className="row">
        <div className="mb-4 col-12 col-sm-12 col-md-4 col-lg-4">
          <div className="footer-logo">
            <span className="text-waring">
              {/* <GroupWorkIcon className="GroupWorkIcon me-2" /> */}
              <div className="logo mb-3">
                <span className="text-waring cursor-pointer">
                  <h2>
                    <span className="text-warning fs-2">The Elites Hub</span>
                  </h2>
                </span>
              </div>
            </span>
          </div>
          <div className="footer-para">
            <span>
              The Elites Hub is bringing the learner community of all age groups
              under one roof to learn, implement, and grow with the dynamic
              skills of the future to help you stand out of the crowd.
            </span>
          </div>
          <div className="social-icons d-flex mb-4">
            <Link
              target="_blank"
              to="https://www.instagram.com/the_elites_hub_official/"
              style={{ color: "#fff" }}
            >
              <InstagramIcon
                className="icon"
                style={{
                  background:
                    "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)",
                }}
              />
            </Link>
            <Link target="_blank" to="https://www.youtube.com/@TheElitesHub">
              <YouTubeIcon
                className="icon"
                style={{ color: "white", backgroundColor: "#FF0808" }}
              />
            </Link>
            <Link
              target="_blank"
              to="https://www.facebook.com/people/The-Elites-Hub/61555680921190/"
              style={{ color: "#fff" }}
            >
              <FacebookIcon
                className="icon"
                style={{
                  background: "#316FF6",
                }}
              />
            </Link>
            <Link target="_blank" to="mailto:contact@theeliteshub.com">
              <EmailIcon
                className="icon"
                style={{ color: "white", backgroundColor: "#FF0808" }}
              />
            </Link>
            <Link target="_blank" to="https://wa.me/917876939551">
              <WhatsAppIcon
                className="icon"
                style={{ color: "white", backgroundColor: "green" }}
              />
            </Link>
            {/* contact@theeliteshub.com */}
          </div>
        </div>
        <div className="mb-4 col-6 col-sm-6 col-md-3 col-lg-3">
          <h5 className="line">Support</h5>
          <div className="support-links d-flex flex-column">
            {/* <Link to="/contact">Contact</Link> */}
            <Link to="/disclaimer">Disclaimer</Link>
            <Link to="/terms-and-conditions">Terms & Conditions</Link>
            <Link to="/refund-policy">Refund Policy</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </div>
        </div>
        <div className="mb-4 col-6 col-sm-6 col-md-3 col-lg-3">
          <h5 className="line">Useful Links</h5>
          <div className="support-links d-flex flex-column">
            <Link to="/">Home</Link>
            <Link to="/about">About</Link>
            <Link to="/contact">Contact</Link>
            {/* <Link to="/service">Services</Link> */}
            <Link to="/register">Became an Affiliate</Link>
          </div>
        </div>
        <div className="mb-4 col-12 col-sm-12 col-md-2 col-lg-2">
          <h5 className="line">Partnership</h5>
          <div className="support-links d-flex flex-column">
            <Link>Become an investor (coming soon)</Link>
          </div>
        </div>
      </div>
      <hr style={{ color: "#fff" }} />
      <div className="copyright text-center">
        <span>
          <small>
            All Rights Reserved by The Elites Hub (Rahul Sharma) © 2024 |
            Designed & Developed by{" "}
            <Link
              className="text-warning text-decoration-none"
              target="_blank"
              to="https://aashirdigital.com"
            >
              ~@aashirdigital
            </Link>
          </small>
        </span>
      </div>
    </div>
  );
};

export default Footer;
