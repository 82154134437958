import React, { useEffect, useState } from "react";
import AdminHeader from "../Header/AdminHeader";
import AdminLayout from "../layout/AdminLayout";
import axios from "axios";
import { message } from "antd";

const AdminQueries = () => {
  const [queries, setQueries] = useState(null);
  const [data, setData] = useState(null);
  const [fitlerQueries, setFilterQueries] = useState(null);

  const getDashboardCount = async () => {
    try {
      const res = await axios.get("/api/admin/get-dashboard-count", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setQueries(res.data.data.contacts.reverse());
        setData(res.data.data.contacts.reverse());
      } else {
        message.error("Error");
      }
    } catch (error) {
      console.error("Error fetching user count:", error);
    }
  };

  const handleChangeStatus = async (id) => {
    try {
      const res = await axios.post("/api/admin/handle-query", { id });
      if (res.data.success) {
        message.success(res.data.message);
        getDashboardCount();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFilter = (e) => {
    if (e.target.value === "") {
      return setFilterQueries(data);
    }
    const filter = queries?.filter(
      (item) => item.status.toString() === e.target.value.toString()
    );
    setFilterQueries(filter);
  };

  useEffect(() => {
    setFilterQueries(data);
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    getDashboardCount();
    // eslint-disable-next-line
  }, []);

  return (
    <AdminHeader>
      <AdminLayout>
        <div className="tab">
          <div className="welcome-container">
            <div className="username d-flex justify-content-between">
              <span>
                <b>All</b> Queries
              </span>
              <span>
                Total Queries: <b>{queries?.length}</b>
              </span>
              <span>
                <select name="" id="" onChange={handleFilter}>
                  <option value="">All Queries</option>
                  <option value="1">Seen</option>
                  <option value="0">Unseen</option>
                </select>
              </span>
            </div>
          </div>
          <div className="w-100 mt-4 " style={{ overflow: "auto" }}>
            <table className="w-100">
              <thead>
                <tr>
                  <th scope="col" className="">
                    #
                  </th>
                  <th scope="col" className="">
                    Name
                  </th>
                  <th scope="col" className="">
                    Email
                  </th>
                  <th scope="col" className="">
                    Message
                  </th>
                  <th scope="col" className="">
                    Date
                  </th>
                  <th scope="col" className="">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {fitlerQueries &&
                  fitlerQueries?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <small>{index + 1}</small>
                        </td>
                        <td>
                          <small>{item.name}</small>
                        </td>
                        <td>
                          <small>{item.email}</small>
                        </td>
                        <td>
                          <small>{item.message}</small>
                        </td>
                        <td>
                          <small>
                            {new Date(item.createdAt).toLocaleString(
                              "default",
                              {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                              }
                            )}
                          </small>
                        </td>
                        <td>
                          {item?.status === 0 ? (
                            <button
                              onClick={() => handleChangeStatus(item?._id)}
                              className="btn btn-success"
                            >
                              <small>Read</small>
                            </button>
                          ) : (
                            "Seen"
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </AdminLayout>
    </AdminHeader>
  );
};

export default AdminQueries;
