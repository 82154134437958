import DashboardIcon from "@mui/icons-material/Dashboard";
import SchoolIcon from "@mui/icons-material/School";
import LinkIcon from "@mui/icons-material/Link";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import FaceIcon from "@mui/icons-material/Face";

const menu = [
  {
    name: "Dashboard",
    path: "/dashboard",
    icon: DashboardIcon,
  },
  {
    name: "My Courses",
    path: "/mycourse",
    icon: SchoolIcon,
  },
  {
    name: "Referral Link",
    path: "/referlink",
    icon: LinkIcon,
  },
  // {
  //   name: "Leaderboard",
  //   path: "/leaderboard",
  //   icon: LeaderboardIcon,
  // },
  {
    name: "Wallet",
    path: "/wallet",
    icon: AccountBalanceWalletIcon,
  },
  {
    name: "Commission Structure",
    path: "/commisionstructure",
    icon: AccountTreeIcon,
  },
  {
    name: "Account Records",
    path: "/records",
    icon: AccountBoxIcon,
  },
  {
    name: "Payment Method",
    path: "/payment",
    icon: AccountBalanceIcon,
  },
  {
    name: "My Profile",
    path: "/profile",
    icon: FaceIcon,
  },
];

export default menu;
