import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import AdminLayout from "../layout/AdminLayout";
import AdminHeader from "../Header/AdminHeader";
// import { message } from "antd";
import "./addBanner.css";

function AddBanner() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [banners, setBanners] = useState(null);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  //! UPLOAD BANNER
  const handleUpload = (e) => {
    e.preventDefault();
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      axios
        .post("/api/banner/add-banner", formData)
        .then((response) => {
          getBanners();
          console.log("File uploaded successfully:", response.data);
        })
        .catch((error) => {
          // Handle upload error
          console.error("Error uploading file:", error);
        });
    }
  };

  //! DELETE BANNER
  const handleDelete = async (filePath) => {
    try {
      const res = await axios.delete("/api/banner/delete-banner", {
        data: { filePath },
      });
      if (res.data.success) {
        getBanners();
      } else {
      }
    } catch (error) {
      console.error("Error deleting banner:", error);
    }
  };

  const getBanners = async () => {
    try {
      const res = await axios.get("/api/banner/get-banners");
      if (res.data.success) {
        setBanners(res.data.data);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBanners();
  }, []);

  return (
    <AdminHeader>
      <AdminLayout>
        <div className="tab">
          <div className="welcome-container">
            <div className="username d-flex justify-content-between">
              <span>
                <b>Add</b> Banner
              </span>
            </div>
          </div>
          <div className="w-100 mt-4" style={{ overflow: "auto" }}>
            <form encType="multipart/form-data">
              <input
                type="file"
                name="file"
                className="form-control"
                onChange={handleFileChange}
              />
              <button className="btn btn-primary mt-2" onClick={handleUpload}>
                Upload
              </button>
            </form>
          </div>
        </div>
        <div className="banner-container">
          {banners &&
            banners?.map((item, index) => {
              return (
                <div className="banners">
                  <img key={index} src={item.filePath} alt="" />
                  <button
                    onClick={() => handleDelete(item.filePath)}
                    className="btn btn-danger"
                  >
                    Delete
                  </button>
                </div>
              );
            })}
        </div>
      </AdminLayout>
    </AdminHeader>
  );
}

export default AddBanner;
