import React, { useEffect, useState } from "react";
import AdminLayout from "../layout/AdminLayout";
import AdminHeader from "../Header/AdminHeader";
import "./AdminDashboard.css";
import GroupIcon from "@mui/icons-material/Group";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import GolfCourseIcon from "@mui/icons-material/GolfCourse";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { message } from "antd";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [allUsers, setAllUsers] = useState(null);
  const [queries, setQueries] = useState(null);
  const [userCount, setUserCount] = useState(null);
  const [courseCount, setCourseCount] = useState(null);
  const [paymentCount, setPaymentCount] = useState(null);
  const [bannerCount, setBannerCount] = useState(null);
  const [error, setError] = useState(null);

  const getDashboardCount = async () => {
    try {
      const res = await axios.get("/api/admin/get-dashboard-count", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setAllUsers(res.data.data.allusers.reverse());
        setQueries(res.data.data.contacts.reverse());
        setUserCount(res.data.data.totalUsers);
        setCourseCount(res.data.data.totalCourses);
        setPaymentCount(res.data.data.totalPayments);
        setBannerCount(res.data.data.totalBanners);
      } else {
        message.error("Error");
      }
    } catch (error) {
      console.error("Error fetching user count:", error);
      setError("Failed to fetch user count. Please try again later.");
    }
  };

  useEffect(() => {
    getDashboardCount();
  }, []);

  return (
    <AdminHeader>
      <AdminLayout className="a-dashboard">
        <div className="admin-dashboard-container">
          <div
            className="admin-dashboard-card"
            onClick={() => navigate("/admin-users")}
          >
            <div className="details">
              <h1>
                <b>{userCount || 0}</b>
              </h1>
              <span>All Users</span>
            </div>
            <div className="icon-container">
              <GroupIcon className="icon" />
            </div>
          </div>
          <div
            className="admin-dashboard-card"
            onClick={() => navigate("/admin-course")}
          >
            <div className="details">
              <h1>
                <b>{courseCount || 0}</b>
              </h1>
              <span>Course</span>
            </div>
            <div className="icon-container">
              <GolfCourseIcon className="icon" />
            </div>
          </div>
          <div
            className="admin-dashboard-card"
            onClick={() => navigate("/admin-payments")}
          >
            <div className="details">
              <h1>
                <b>{paymentCount || 0}</b>
              </h1>
              <span>Payments</span>
            </div>
            <div className="icon-container">
              <AccountBalanceIcon className="icon" />
            </div>
          </div>
          <div
            className="admin-dashboard-card"
            onClick={() => navigate("/admin-add-banner")}
          >
            <div className="details">
              <h1>
                <b>{bannerCount || 0}</b>
              </h1>
              <span>
                <ViewCarouselIcon />
              </span>
            </div>
            <div className="icon-container">
              <GroupIcon className="icon" />
            </div>
          </div>
        </div>
        <div className="admin-recent-things">
          <div className="admin-recent-users">
            <h6>Recent Users</h6>
            <hr />
            <table className="mt-3">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Mobile</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {allUsers &&
                  allUsers
                    .map((item, index) => {
                      return (
                        <tr>
                          <td>{item.name}</td>
                          <td>{item.email}</td>
                          <td>{item.number}</td>
                          <td>{item.active ? "Yes" : "No"}</td>
                        </tr>
                      );
                    })
                    .splice(0, 8)}
              </tbody>
            </table>
          </div>
          <div className="admin-recent-queries">
            <h6>Recent Queries</h6>
            <hr />
            <table className="mt-3">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Message</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {queries &&
                  queries
                    .map((item, index) => {
                      return (
                        <tr>
                          <td>{item.name}</td>
                          <td>{item.email}</td>
                          <td>
                            <button className="btn btn-dark">View</button>
                          </td>
                          <td>
                            <small>
                              <small>
                                {new Date(item.createdAt).toLocaleString(
                                  "default",
                                  {
                                    day: "numeric",
                                    month: "long",
                                    year: "numeric",
                                  }
                                )}
                              </small>
                            </small>
                          </td>
                        </tr>
                      );
                    })
                    .splice(0, 8)}
              </tbody>
            </table>
          </div>
        </div>
      </AdminLayout>
    </AdminHeader>
  );
};

export default AdminDashboard;
