import React, { useEffect, useState } from 'react'
import "./Course.css";
import axios from 'axios';
import Header from "../components/Header/Header";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import { useParams } from "react-router-dom";
import Layout from "./layout/Layout";
import { SetAllCourseVideos } from "../redux/features/videoSlice";
import { useDispatch, useSelector } from 'react-redux';

const Course = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [sequence, setSequence] = useState(1)
  const [seqVideo, setSeqVideo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [course, setCourse] = useState(null);
  const { allCourseVideos } = useSelector((state) => state.allCourseVideos);


  const getCourse = async ()=>{
    axios({
      method: "POST",
      data: {
        courseName: params?.courseName?.replace(/-/g, ' ')
      },
      withCredentials: true,
      url: `api/course/get-course-by-name`,
    })
      .then((res) => {
        setCourse(res.data.data);
        dispatch(SetAllCourseVideos(res.data.data.videoLinks));
      })  
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePreviousButton = ()=>{
    setSequence(sequence - 1)
  }
  const handleNextButton = ()=>{
    setSequence(sequence + 1)
  }

  useEffect(()=>{
    getCourse();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( allCourseVideos?.length > 0) {
      const initialVideo = allCourseVideos?.find((video, index) => index + 1 === sequence);
  
      if (initialVideo) {
        console.log(initialVideo)
        setSeqVideo(initialVideo);
      }
    }
  }, [sequence, allCourseVideos]);

  const extractVideoId = (url) => {
    const match = url?.match(/(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/);
    return match ? match[1] : null;
  };

  return (
    <Header>
      <Layout setSequence={setSequence} sequence={sequence} loading={loading}>
        {!loading?
        <div className='row'>
          <div className="video">
            <div className="mb-4 ">
              <h5 className='border px-3 py-1 bg-warning text-white' style={{borderRadius: '5px'}}>
                {seqVideo?.title}
              </h5>
            </div>
            <iframe
              className='course-youtube-video'
              src={`https://www.youtube.com/embed/${extractVideoId(seqVideo?.link)}`}
              title={seqVideo?.title}
              allowFullScreen
            ></iframe>
            {/* <video src=""></video> */}
            <div className="act-button">
              <button className={` ${sequence === 1? 'act-button-disable' : 'act-button-enable'}`} disabled={sequence === 1} onClick={handlePreviousButton}>
                <SkipPreviousIcon />
                Prev
              </button>
              <button className={` ${sequence === allCourseVideos?.length? 'act-button-disable' : 'act-button-enable'}`} disabled={sequence === allCourseVideos?.length} onClick={handleNextButton}>
                Next
                <SkipNextIcon />
              </button>
            </div>
            <div className="feedback">
              <textarea name="" id="" cols="30" rows="3"></textarea>
              <button>Submit</button>
            </div>
          </div>
          <div className="video-side">
          </div>
          
        </div> 
        :
        <div className="d-flex ">
          <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
          <span className="ms-2">Loading...</span>
        </div>
        }
      </Layout>
    </Header>
  );
};

export default Course;
