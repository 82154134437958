import React from "react";
import "./HowItWorks.css";
import IMAGES from "../../img";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import StarsIcon from "@mui/icons-material/Stars";
import PaidIcon from "@mui/icons-material/Paid";

const HowItWorks = () => {
  return (
    <div className="py-5 container-fluid how-it-works">
      <h2 className="text-lg-center mb-5">
        How
        <span> Elites Hub</span> Works?
      </h2>
      <div className="row">
        <div className="px-3 px-lg-5 text-center col-12 col-sm-12 col-md-4 col-lg-4 mb-4">
          <HowToRegIcon style={{ fontSize: "70px" }} />
          <h5 className="fw-bold my-2">Sign Up</h5>
          <h6 className="mb-4">Be a part of Elites Hub</h6>
          <p className="text-muted">
            Where passion meets profession. Elevate your career with innovative
            learning, collaborative projects, and a vibrant community. Join us
            in shaping the future of work, together!
          </p>
        </div>
        <div className="px-3 px-lg-5 text-center col-12 col-sm-12 col-md-4 col-lg-4 mb-4">
          <StarsIcon style={{ fontSize: "70px" }} />
          <h5 className="fw-bold my-2">Activate Account</h5>
          <h6 className="mb-4">Get the premium access</h6>
          <p className="text-muted">
            Elevate your career with Premium Access: unlimited content, advanced
            workshops, personalized coaching, and priority support. Join Elites
            Hub's premium membership for exclusive benefits and accelerated
            growth.
          </p>
        </div>
        <div className="px-3 px-lg-5 text-center col-12 col-sm-12 col-md-4 col-lg-4 mb-4">
          <PaidIcon style={{ fontSize: "70px" }} />
          <h5 className="fw-bold my-2">Earn Money</h5>
          <h6 className="mb-4">Withdraw money easily</h6>
          <p className="text-muted">
            Transform your virtual coins into real cash effortlessly. Elites Hub
            offers a straightforward conversion system, turning your digital
            earnings into tangible rewards. Make it real, now!
          </p>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
