import React from "react";
import Header from "../components/Header/Header";
import "./Register.css";

const UpdatePass = () => {
  return (
    <Header>
      <div className="container-fluid bg-light register-form-container">
        <div className="form">
          <div className="row">
            <div className="register-img col-12 col-sm-12 col-md-4 col-lg-4">
              {/* <img width="100%" src={IMAGES.register} alt="load" /> */}
            </div>
            <div className="col-12 col-sm-12 col-md-8 col-lg-8 px-4">
              <form>
                <h5 className="mb-4 position-relative line">Hurray!</h5>
                {/* Password  */}
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Password
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="Enter password"
                    aria-describedby="emailHelp"
                    required
                  />
                </div>{" "}
                {/* Confirm Pass  */}
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Password
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="Same password"
                    aria-describedby="emailHelp"
                    required
                  />
                </div>
                <button type="submit" className="reg-btn">
                  Update
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Header>
  );
};

export default UpdatePass;
