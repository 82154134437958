import React from "react";

const RechargeHistory = ({addCoinAllRecords, totalHeading, totalValue, loading}) => {
  return (
    <div className="tab">
      <div className="welcome-container">
        <div className="username d-flex justify-content-between align-items-center">
          <span>
            <b>My Recharge History</b>
          </span>
          <b>{totalHeading}: {totalValue}</b>
        </div>
      </div>
      {!loading?<table className="table">
        <thead>
          <tr>
            <th>Id</th>
            <th>Phone</th>
            <th>Status</th>
            <th>Coins</th>
            <th>Payment Mode</th>
          </tr>
        </thead>
        <tbody>
          {addCoinAllRecords?.map((record, index)=>{
            return  <tr>
                      <td data-label="Id">{index + 1}</td>
                      <td data-label="Phone">{record?.number}</td>
                      <td data-label="Status">Success</td>
                      <td data-label="Coins">{record?.amount}</td>
                      <td data-label="Payment Mode">{record?.payType}</td>
                    </tr>
          })}
        </tbody>
      </table>
      :
      <div className="d-flex align-items-center justify-content-center mt-4">
        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
        <span className="ms-2">Loading...</span>
      </div>
      }
    </div>
  );
};

export default RechargeHistory;
