import React from "react";
import "./ChooseBetter.css";
import IMAGES from "../../img/index";

const ChooseBetter = () => {
  return (
    <div className="choose-better-container">
      <h2 className="text">
        <span>Choose better</span> <br />
        with Fisdom
      </h2>
      <div className="fisdom-container">
        <div className="fisdom-card fisdom-card2">
          <div className="fisdom-icon">
            <img width="70px" src={IMAGES.site3} alt="" />
          </div>
          <div className="fisdom-content">
            <span>Online Chapters</span>
            <br />
            <h3 className="mt-2">
              <b>100+</b>
            </h3>
          </div>
        </div>
        <div className="fisdom-card">
          <div className="fisdom-icon">
            <img width="70px" src={IMAGES.site} alt="" />
          </div>
          <div className="fisdom-content">
            <span>Enrolled Students</span>
            <br />
            <h3 className="mt-2">
              <b>8000+</b>
            </h3>
          </div>
        </div>
        <div className="fisdom-card fisdom-card3">
          <div className="fisdom-icon">
            <img width="70px" src={IMAGES.site2} alt="" />
          </div>
          <div className="fisdom-content">
            <span>Total Earnings</span>
            <br />
            <h3 className="mt-2">
              <b>50,00,000/-</b>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseBetter;
