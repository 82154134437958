import React, { useState } from "react";
import SideMenu from "../../components/SideMenu/SideMenu";
import BackDrop from "../../components/SideMenu/BackDrop";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import LoginIcon from "@mui/icons-material/Login";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import { Link, useNavigate } from "react-router-dom";
import AdminSideMenu from "../layout/AdminSideMenu";
import "./AdminHeader.css";
import AdminBackdrop from "../layout/AdminBackdrop";
import { useDispatch, useSelector } from "react-redux";
import { SetLogin } from "../../redux/features/loginSlice";
import axios from "axios";
import IMAGES from "../../img";

const AdminHeader = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { admin } = useSelector((state) => state.admin);
  const [adminSideMenu, setAdminSideMenu] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [loginUser, setLoginUser] = useState(false);

  const handleLogout = () => {
    setLoading(true);
    axios({
      method: "POST",
      withCredentials: true,
      url: "api/user/logout",
    })
      .then((res) => {
        setTimeout(() => {
          dispatch(SetLogin(false));
          setLoginUser(false);
          setLoading(false);
          navigate("/login");
        }, 1500);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  return (
    <React.Fragment>
      <header
        className="admin-header-main pop"
        style={{ position: "relative" }}
      >
        {/* Mobile Menu */}
        <AdminSideMenu
          adminSideMenu={adminSideMenu}
          setAdminSideMenu={setAdminSideMenu}
        />
        <AdminBackdrop
          adminSideMenu={adminSideMenu}
          setAdminSideMenu={setAdminSideMenu}
        />
        {/* Desktop Menu */}
        <div className="logo">
          <span
            className="text-white cursor-pointer"
            onClick={() => {
              navigate("/");
            }}
          >
            The Elite <span className="text-warning">Hub</span>
            {/* <img width="130px" src={IMAGES.edyguru} alt="" /> */}
            {/* <h2>edyguru</h2> */}
          </span>
        </div>
        {/* action buttons  */}
        <div className={`action-btns`}>
          <div>
            {admin && admin ? (
              <button className="register-btn" onClick={handleLogout}>
                Logout
                <PersonIcon className="PersonIcon ms-2" />
              </button>
            ) : (
              <button className="login-btn" onClick={() => navigate("/login")}>
                Login
                <LoginIcon className="LoginIcon ms-2" />
              </button>
            )}
          </div>
        </div>

        {/* Burger Icon  */}
        <div className="mobile-menu">
          <MenuIcon
            className="MenuIcon"
            onClick={() => setAdminSideMenu(!adminSideMenu)}
          />
        </div>
      </header>
      <div className="children">{children}</div>
    </React.Fragment>
  );
};

export default AdminHeader;
