import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import "./CourseHistory.css";
import { useNavigate } from "react-router-dom";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import LockIcon from '@mui/icons-material/Lock';
import { useSelector } from "react-redux";
import IMAGES from "../../img";

const CoursePurchaseDetails = () => {
    const params = useParams();
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.user);
    const [allCourse, setAllCourse] = useState(null)
    const [course, setCourse] = useState(null)

    const getUserDetails = () => {
        setLoading(true);
        axios({
          method: "POST",
          data: {
            email: params?.tab,
          },
          withCredentials: true,
          url: `api/user/get-user-details`,
        })
          .then((res) => {
            if (res.data.success) {
              setTimeout(() => {
                setLoading(false);
                setUserData(res.data.data)
              }, 1500);
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
    };

    const getAllCourse = async ()=>{
      setLoading(true);
      axios({
        method: "get",
        withCredentials: true,
        url: `api/course/get-all-course`,
      })
        .then((res) => {
          setLoading(false)
          setAllCourse(res.data.data);
        })  
        .catch((err) => {
          setLoading(false)
          console.log(err);
        });
    };

    
    function capitalizeFirstLetter(str) {
      if (typeof str !== 'string' || str.length === 0) {
        return str;
      }
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    
    useEffect(()=>{
      getAllCourse();
      // eslint-disable-next-line
    }, []);

      
    useEffect(() => {
      if(params?.tab){
          getUserDetails();
      }
      // eslint-disable-next-line
    }, [params?.tab]);
  return (
    <>
      <div className="tab">
        <div className="welcome-container">
          <div className="username">
            <span>
              <b>{'Course Purchase History'}</b>
            </span>
          </div>
        </div>
        <div className="user-course-container">
          {!loading && allCourse?.map((course)=>{
            const lockCourse = userData?.course?.find((elm)=> {
              return elm?.courseName === course?.courseName
            })
            return  (
                      <div className="user-course" key={course?._id}>
                        <div className="user-course-content">
                          <h5 className="fs-5">{capitalizeFirstLetter(course?.courseName)} Course</h5>
                          <span className="mb-3">Duration: 30 days</span>
                          {!lockCourse?
                            <>
                            <div className="d-flex justify-content-between align-items-center">
                              <span className="fw-bold d-flex justify-content-between align-items-center">
                                <span className="fs-4 text-danger">{course?.price}</span>
                                <span><img className="ms-2" width="28px" src={IMAGES.coin} alt="" /></span>
                              </span>
                              <button disabled={userData} className="course-btn bg-danger" onClick={() => navigate(`/courseInfo/${course?.courseName}`)}><LockIcon className="fs-5"/> {userData? 'Locked' : 'Unlock'} </button>
                            </div>
                            </>
                            :
                            <div className="d-flex justify-content-between align-items-center">
                              <button className="course-btn" onClick={() => navigate(`/course/${course?.courseName}`)}> Watch Now </button>
                              <span className="me-4 text-primary">Ashir Ansari</span>
                            </div>
                          }
                        </div>
                      </div>
                    )
            })
          }
          { loading && 
            <div className="d-flex align-items-center justify-content-center">
              <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
              <span className="ms-2">Loading...</span>
            </div>
          }
        </div>
      </div>
    </>
  )
}

export default CoursePurchaseDetails
