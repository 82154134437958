import React, { useState } from "react";
import AdminMenu from "./AdminMenu";
import { Link, useLocation } from "react-router-dom";
import "./AdminLayout.css";
import { useSelector } from "react-redux";

const AdminLayout = ({ children }) => {
  const location = useLocation();
  return (
    <div className="admin-dashboard container-fluid">
      <div className="left d-lg-block d-none d-md-none">
        <ul>
          {AdminMenu?.map((item, index) => {
            const IconComponent = item.icon;
            return (
              <li
                className={`${
                  location.pathname === `${item.path}` ? "active" : ""
                }`}
              >
                <IconComponent />
                <Link className="ms-2" to={item.path}>
                  {item.name}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="admin-right">{children}</div>
    </div>
  );
};

export default AdminLayout;
