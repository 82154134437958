const IMAGES = {
  //profile
  profile1: require("../img/profile/1.jpg"),

  //logo
  edyguru: require("../img/edyguru.png"),
  courseImage: require("../img/course/digital.jpg"),
  // home
  reg: require("../img/3.png"),
  heroimg: require("../img/home/hero-img.png"),
  heroimg2: require("../img/home/hero-img2.png"),
  heroimg3: require("../svg/hero3.png"),
  courseimg1: require("../img/course/hero-section.png"),
  heroBg: require("../img/home/hero.png"),
  site: require("../img/home/enroll.png"),
  site1: require("../img/home/student.png"),
  site2: require("../img/home/money.png"),
  site3: require("../img/home/online-learning.png"),
  user: require("../img/home/r1.jpeg"),
  money1: require("../img/home/money1.png"),
  dollar: require("../img/home/dollar.png"),
  learner: require("../img/home/learner.jpg"),
  finger: require("../img/home/course-learn.png"),

  // register
  gallery: require("../img/picture.png"),

  // Payment Page
  paytm: require("../img/payment/paytm.png"),
  gpay: require("../img/payment/gpay.png"),
  phonepe: require("../img/payment/phonepe.png"),
  upi: require("../img/payment/upi.png"),
  ba: require("../img/payment/ba.png"),

  // howItworks
  hw1: require("../img/home/signup.png"),
  hw2: require("../img/home/activate.png"),
  hw3: require("../img/home/earn.png"),
  other: require("../img/home/othersay.webp"),
  // aboutBg: require("../img/about/bg.jpg"),
  br: require("../img/activate/barcode.jpeg"),
  activate: require("../img/activate/activate.png"),
  course: require("../img/course/course.webp"),
  // contact
  contact: require("../img/contact/contact.jpg"),
  contact2: require("../img/contact/contact2.png"),
  contact3: require("../img/contact/contact3.png"),
  call: require("../img/contact/call.png"),
  location: require("../img/contact/location.png"),
  openEmail: require("../img/contact/open-email.png"),
  //course
  ash: require("../img/userImage/aashir.png"),
  cardbg: require("../img/dashboard/cardbg.png"),
  chip: require("../img/dashboard/atm_chip.webp"),
  walletbb: require("../img/dashboard/wallet_bb.png"),
  coin: require("../img/star.png"),
  wordpress: require("../img/course/wordpress.jpg"),
  digital: require("../img/course/digital.jpg"),
  wptrain: require("../img/course/wordpress-train.jpg"),
  //comission
  comBanner: require("../img/comission/comm_banner.jpg"),
  // activate page
  cashOff: require("../img/activate/cash-off.webp"),
  disCoupon: require("../img/activate/dis_coupon.png"),
  disCoupon2: require("../img/activate/dis_coupon2.webp"),
  freeCourse: require("../img/activate/free-course.png"),
  luckyDraw: require("../img/activate/lucky-draw.png"),
  offerEarly: require("../img/activate/offer_early.png"),
  passIncome: require("../img/activate/pass-income.png"),
  primeTrainer: require("../img/activate/prime-trainer.webp"),
  prime: require("../img/activate/prime.jpg"),
  wallet: require("../img/activate/wallet.png"),
  wordpress: require("../img/activate/wordpress.png"),
  digitalMarketing: require("../img/activate/bullhorn.png"),
  affiliateLink: require("../img/activate/affiliate-marketing.png"),
  satisfaction: require("../img/activate/satisfaction.png"),

  // service
  service2: require("../img/service/service2.png"),
  html: require("../img/service/html.png"),
  css: require("../img/service/css.png"),
  js: require("../img/service/js.png"),
  dm: require("../img/service/digital.png"),
  figma: require("../img/service/figma.png"),
  excel: require("../img/service/excel.png"),
  react: require("../img/service/react.png"),
  student: require("../img/service/service.png"),

  // user image
  herobg: require("../img/home/herobg.webp"),
  r1: require("../img/home/r1.jpeg"),
  r2: require("../img/home/r2.jpg"),
  r3: require("../img/home/r3.jpg"),
  r4: require("../img/home/r4.jpg"),
  r5: require("../img/home/r5.jpg"),
  r6: require("../img/home/r6.jpg"),
  r7: require("../img/home/r7.jpg"),
  r8: require("../img/home/r8.jpg"),
  r9: require("../img/home/r9.jpeg"),
  r10: require("../img/home/r10.jpg"),
  r11: require("../img/home/r11.jpeg"),
  r12: require("../img/home/r12.jpeg"),
  r13: require("../img/home/r13.jpeg"),
  r14: require("../img/home/r14.jpeg"),

  // course page images
  wpCourse: require("../img/course/courseImg/1.png"),
  htmlCourse: require("../img/course/courseImg/2.png"),
  digitalMarketingCourse: require("../img/course/courseImg/3.png"),

  // course author
  aashiransari: require("../img/course/author/aashir.png"),

  // course author
  p1: require("../img/home/p1.jpeg"),
  p2: require("../img/home/p2.webp"),
  p3: require("../img/home/p3.jpeg"),

  // COURSE PAGE
  b1: require("../img/course/b1.png"),
  b2: require("../img/course/b2.png"),
  c1: require("../img/course/c1.png"),
  c2: require("../img/course/c2.png"),
  c3: require("../img/course/c3.png"),
  c4: require("../img/course/c4.png"),
  c5: require("../img/course/c5.png"),
  c6: require("../img/course/c6.png"),
  c7: require("../img/course/c7.png"),
  c8: require("../img/course/c8.png"),
  c9: require("../img/course/c9.png"),
  c10: require("../img/course/c10.png"),
  c11: require("../img/course/c11.png"),
  c12: require("../img/course/c12.png"),
  c13: require("../img/course/c13.png"),
  c14: require("../img/course/c14.png"),
  c15: require("../img/course/c15.png"),
  c16: require("../img/course/c16.png"),
  c17: require("../img/course/c17.png"),
  c18: require("../img/course/c18.png"),
};

export default IMAGES;
