import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Faq from "../Home/Faq";
import IMAGES from "../../img";
import "./singleCourseDetails.css";
import { useNavigate, useParams } from "react-router-dom";
import allCourse from "../Course/CourseArray";
import trendingCourse from "../Course/CourseArray";

const SingleCourseDetails = () => {
  const trendingCourse = [
    {
      name: "Basic To Advance SEO",
      category: "Digital Marketing",
      profile: IMAGES?.profile1,
      instructor: "Ashir Ansari",
      rating: "4.6",
      level: "Basic to Advance",
      lessions: "7",
      image: `${IMAGES.wpCourse}`,
      overvieww: [
        {
          heading: "Keyword Research",
          desc: "Identifying relevant keywords that your target audience uses in search queries.",
        },
        {
          heading: "On-Page SEO",
          desc: "Optimizing individual web pages with keyword placement, meta tags, headings, and quality content.",
        },
        {
          heading: "Content Quality",
          desc: "Creating high-quality, informative, and engaging content that resonates with users.",
        },
        {
          heading: "Title and Meta Description",
          desc: "Crafting compelling titles and meta descriptions for search engine results pages (SERPs).",
        },
        {
          heading: "URL Structure",
          desc: "Creating user-friendly and descriptive URLs.",
        },
        {
          heading: "Internal Linking",
          desc: "Connecting related pages within your website for better navigation.",
        },
        {
          heading: "User Experience (UX)",
          desc: "Ensuring a smooth and user-friendly website experience.",
        },
      ],
      whatLearn: [
        {
          desc: "To rank 1 on Google search",
        },
        {
          desc: "On Page & off Page SEO",
        },
        {
          desc: "Technical SEO",
        },
      ],
      requirements: [
        "Internet Connection",
        "Laptop",
        "Must Have a Ready Website.",
        "Must Have Knowledge to Write the Content.",
        "Must Have Social Media Business Accounts.",
      ],
      skillsCovered: [
        "Understanding of how search engines crawl, render and index websites",
        "In-depth keyword research tools with various tools",
        "How to optimise your on-page content to rank well in Google",
        "How to build high quality SEO backlinks that move the needle",
        "Master technical SEO and outsmart your competitors",
      ],
    },
    {
      name: "Wordpress Course",
      category: "IT",
      profile: IMAGES?.profile1,
      instructor: "Ashir Ansari",
      rating: "4.6",
      level: "Intermediate",
      lessions: "7",
      image: `${IMAGES.wpCourse}`,
      overvieww: [
        {
          heading: "Keyword Research",
          desc: "Identifying relevant keywords that your target audience uses in search queries.",
        },
        {
          heading: "On-Page SEO",
          desc: "Optimizing individual web pages with keyword placement, meta tags, headings, and quality content.",
        },
        {
          heading: "Content Quality",
          desc: "Creating high-quality, informative, and engaging content that resonates with users.",
        },
        {
          heading: "Title and Meta Description",
          desc: "Crafting compelling titles and meta descriptions for search engine results pages (SERPs).",
        },
        {
          heading: "URL Structure",
          desc: "Creating user-friendly and descriptive URLs.",
        },
        {
          heading: "Internal Linking",
          desc: "Connecting related pages within your website for better navigation.",
        },
        {
          heading: "User Experience (UX)",
          desc: "Ensuring a smooth and user-friendly website experience.",
        },
      ],
      whatLearn: [
        {
          desc: "To rank 1 on Google search",
        },
        {
          desc: "On Page & off Page SEO",
        },
        {
          desc: "Technical SEO",
        },
      ],
      requirements: [
        "Internet Connection",
        "Laptop",
        "Must Have a Ready Website.",
        "Must Have Knowledge to Write the Content.",
        "Must Have Social Media Business Accounts.",
      ],
      skillsCovered: [
        "Understanding of how search engines crawl, render and index websites",
        "In-depth keyword research tools with various tools",
        "How to optimise your on-page content to rank well in Google",
        "How to build high quality SEO backlinks that move the needle",
        "Master technical SEO and outsmart your competitors",
      ],
    },
    {
      name: "The Complete Digital Marketing",
      profile: IMAGES?.profile1,
      instructor: "Ashir Ansari",
      rating: "4.6",
      category: "Digital Marketing",
      level: "Basic to Advance",
      lessions: "7",
      image: `${IMAGES.wpCourse}`,
      overvieww: [
        {
          heading: "Keyword Research",
          desc: "Identifying relevant keywords that your target audience uses in search queries.",
        },
        {
          heading: "On-Page SEO",
          desc: "Optimizing individual web pages with keyword placement, meta tags, headings, and quality content.",
        },
        {
          heading: "Content Quality",
          desc: "Creating high-quality, informative, and engaging content that resonates with users.",
        },
        {
          heading: "Title and Meta Description",
          desc: "Crafting compelling titles and meta descriptions for search engine results pages (SERPs).",
        },
        {
          heading: "URL Structure",
          desc: "Creating user-friendly and descriptive URLs.",
        },
        {
          heading: "Internal Linking",
          desc: "Connecting related pages within your website for better navigation.",
        },
        {
          heading: "User Experience (UX)",
          desc: "Ensuring a smooth and user-friendly website experience.",
        },
      ],
      whatLearn: [
        {
          desc: "To rank 1 on Google search",
        },
        {
          desc: "On Page & off Page SEO",
        },
        {
          desc: "Technical SEO",
        },
      ],
      requirements: [
        "Internet Connection",
        "Laptop",
        "Must Have a Ready Website.",
        "Must Have Knowledge to Write the Content.",
        "Must Have Social Media Business Accounts.",
      ],
      skillsCovered: [
        "Understanding of how search engines crawl, render and index websites",
        "In-depth keyword research tools with various tools",
        "How to optimise your on-page content to rank well in Google",
        "How to build high quality SEO backlinks that move the needle",
        "Master technical SEO and outsmart your competitors",
      ],
    },
    {
      name: "2022 Updated Profitable Facebook Ads Method Course",
      profile: IMAGES?.profile1,
      instructor: "Aniket Singh",
      rating: "4.6",
      category: "Social Media Marketing",
      level: "Basic",
      lessions: "7",
      image: `${IMAGES.wpCourse}`,
      overvieww: [
        {
          heading: "Keyword Research",
          desc: "Identifying relevant keywords that your target audience uses in search queries.",
        },
        {
          heading: "On-Page SEO",
          desc: "Optimizing individual web pages with keyword placement, meta tags, headings, and quality content.",
        },
        {
          heading: "Content Quality",
          desc: "Creating high-quality, informative, and engaging content that resonates with users.",
        },
        {
          heading: "Title and Meta Description",
          desc: "Crafting compelling titles and meta descriptions for search engine results pages (SERPs).",
        },
        {
          heading: "URL Structure",
          desc: "Creating user-friendly and descriptive URLs.",
        },
        {
          heading: "Internal Linking",
          desc: "Connecting related pages within your website for better navigation.",
        },
        {
          heading: "User Experience (UX)",
          desc: "Ensuring a smooth and user-friendly website experience.",
        },
      ],
      whatLearn: [
        {
          desc: "To rank 1 on Google search",
        },
        {
          desc: "On Page & off Page SEO",
        },
        {
          desc: "Technical SEO",
        },
      ],
      requirements: [
        "Internet Connection",
        "Laptop",
        "Must Have a Ready Website.",
        "Must Have Knowledge to Write the Content.",
        "Must Have Social Media Business Accounts.",
      ],
      skillsCovered: [
        "Understanding of how search engines crawl, render and index websites",
        "In-depth keyword research tools with various tools",
        "How to optimise your on-page content to rank well in Google",
        "How to build high quality SEO backlinks that move the needle",
        "Master technical SEO and outsmart your competitors",
      ],
    },
  ];
  const navigate = useNavigate();
  const params = useParams();
  const [singleCourse, setSingleCourse] = useState(null);
  const paramsData = params.name;

  useEffect(() => {
    if (paramsData) {
      const courseName = paramsData.replace(/-/g, " ");
      const data = allCourse?.filter((course) => {
        return course?.name === courseName;
      });
      setSingleCourse(data[0]);
    }
    // eslint-disable-next-line
  }, [paramsData]);

  return (
    <React.Fragment>
      <Header>
        <div className="course-hero-sec">
          <div className="container-fluid single-course-sec">
            <div className="single-course-hero-sec">
              <span
                className="py-1 px-3 rounded-pill text-white course-category-hero-sec"
                style={{ backgroundColor: "#b61800" }}
              >
                {singleCourse?.category}
              </span>
              <div>
                <h1 style={{ fontWeight: "600" }}>{singleCourse?.name}</h1>
              </div>
              <div className="single-course-profile-details">
                <div>
                  <img src={singleCourse?.profile} alt="profile" />
                </div>
                <div className="fs-4">{singleCourse?.instructor}</div>
              </div>
              <div className="single-course-enroll-btn">
                <span
                  className="rounded-pill"
                  onClick={() => {
                    navigate("/register");
                  }}
                >
                  Enroll Now
                </span>
              </div>
            </div>
            <div className="single-course-hero-img">
              <img src={singleCourse?.image} alt="loading" />
            </div>
          </div>
        </div>
        <div className="single-course-content-details">
          <div className="single-course-content-sec1">
            <div className="single-course-overview">
              <h3 className="line2">Course Overview</h3>
              <div className="course-overview-table shadow">
                <table className="table ">
                  <tbody>
                    {singleCourse?.overvieww?.map((data) => {
                      return (
                        <tr className="single-course-overview-row m-0">
                          <td
                            className="border-0 ps-0 fs-6"
                            style={{ fontWeight: "600", textAlign: "left" }}
                          >
                            {data?.heading}:
                          </td>
                          <td
                            className="border-0 ps-0 fs-6"
                            style={{ textAlign: "left" }}
                          >
                            {data?.desc}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="single-course-category-details">
              <h3 className="line2">Course Category</h3>
              <table className="table ">
                <tbody>
                  <tr>
                    <td
                      className="fs-6 "
                      style={{ textAlign: "left", fontWeight: "600" }}
                    >
                      Level:
                    </td>
                    <td className="fs-6 " style={{ textAlign: "left" }}>
                      {singleCourse?.level}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="fs-6 "
                      style={{ textAlign: "left", fontWeight: "600" }}
                    >
                      Category:
                    </td>
                    <td className="fs-6 " style={{ textAlign: "left" }}>
                      {singleCourse?.category}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="single-course-content-sec1">
            <div className="single-course-overview mt-2">
              <h3 className="line2">Course Objective</h3>
              <div className="accordion shadow p-4 mt-4" id="accordionExample">
                <div className="accordion-item border-0">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <span style={{ fontWeight: "600", fontSize: "20px" }}>
                        What Will I learn
                      </span>
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {singleCourse?.whatLearn?.map((data) => {
                        return (
                          <div>
                            <p>- {data?.desc}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="accordion-item border-0">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <span style={{ fontWeight: "600", fontSize: "20px" }}>
                        Requirements
                      </span>
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {singleCourse?.requirements?.map((data) => {
                        return (
                          <div>
                            <p>- {data}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="accordion-item border-0">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <span style={{ fontWeight: "600", fontSize: "20px" }}>
                        Skills covered in this course
                      </span>
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {singleCourse?.skillsCovered?.map((data) => {
                        return (
                          <div>
                            <p>- {data}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="single-course-category-details"></div>
          </div>
        </div>
        <hr />
        <div className="container py-5">
          <h2 className="line2 fw-bold p-0 ms-2 mb-3">Trending Course</h2>
          <div className="all-course-content bg-white">
            {trendingCourse?.map((data) => {
              return (
                <>
                  <div
                    className="single-course-content shadow"
                    onClick={() => {
                      navigate(
                        `/edyguru-course/${(data?.name).replace(/\s+/g, "-")}`
                      );
                    }}
                  >
                    <div className="single-course-content-img">
                      <img src={data?.image} alt="Loading" />
                    </div>
                    <div className="single-course-data-content">
                      <h1>{data?.name}</h1>
                      <div
                        className="course-view shadow"
                        onClick={() => {
                          navigate(
                            `/edyguru-course/${(data?.name).replace(
                              /\s+/g,
                              "-"
                            )}`
                          );
                        }}
                      >
                        <span>View Details</span>
                      </div>
                    </div>
                    <div className="single-course-data-profile">
                      <div className="">
                        <img
                          className="rounded-pill"
                          width={60}
                          src={data?.profile}
                          alt=""
                        />
                      </div>
                      <div className="">
                        <span
                          style={{
                            fontSize: "17px",
                            fontWeight: "600",
                            color: "#0066FF",
                          }}
                        >
                          {data?.instructor}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>

        <Faq />
      </Header>
    </React.Fragment>
  );
};

export default SingleCourseDetails;
