import React, { useEffect, useState } from "react";
import Header from "../components/Header/Header";
import IMAGES from "../img";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./Activate.css";
import Faq from "../components/Home/Faq";

const Activate = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [activationFee, setActivationFee] = useState(900);
  const [hashKey, setHashKey] = useState(null);
  const [transactionId, setTransactionId] = useState(null);

  const getUserData = async () => {
    const transactionId = await generateTransactionId();
    axios({
      method: "get",
      withCredentials: true,
      url: `api/user/get-user`,
    })
      .then((res) => {
        if (res.data.success) {
          getHashKey(res.data.data, transactionId);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const generateTransactionId = async () => {
    const timestamp = Date.now();
    const random = Math.floor(Math.random() * 10000);
    const transactionId = `${timestamp}${random}`;
    setTransactionId(transactionId);
    return transactionId; // Return the generated transactionId
  };

  const getHashKey = (data, transactionId) => {
    axios({
      method: "POST",
      data: {
        productinfo: "Edyguru Plan",
        firstname: data?.name,
        email: data?.email,
        phone: data?.number,
        amount: activationFee,
        txnid: transactionId,
      },
      withCredentials: true,
      url: `api/user/get-hashkey`,
    })
      .then((res) => {
        setHashKey(res.data.hash);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getUserData();
    // eslint-disable-next-line
  }, []);

  return (
    <Header>
      <div className="activate-container">
        {/* acitvate form  */}
        <div className="activate-f-container container-fluid text-white">
          <div className="activate-img-container">
            <img className="" src={IMAGES.activate} alt="" />
            <span className="activate-absolute-content">
              <h2 className="m-0">Hello {user?.name}!</h2>
              <p className="m-0">Your accout is not activated</p>
            </span>
          </div>
          <div className="activate-f-content">
            <div className="mb-4 activate-f-content mt-0 w-100">
              <h3 className="mb-3 w-100">Activate Your Account Now</h3>
              <p className="w-100">
                <small>
                  Activate your account instantly with a <b>₹900</b> INR payment
                  via UPI or QR code. Enjoy premium access within 5 minutes.
                  Thank you for choosing us! Scan the barcode or Pay on UPI
                  rahuls3606@fifederal - 900rs and WhatsApp us on{" "}
                  <Link
                    className="text-white"
                    target="_blank"
                    to="https://wa.me/917876939551"
                  >
                    7876939551
                  </Link>
                </small>
              </p>
              <img className="barcode" src={IMAGES.br} alt="" />
              {/* <form action="https://secure.payu.in/_payment" method="POST">
                <input type="hidden" name="key" value="PmqiX5" />
                <input type="hidden" name="txnid" value={transactionId} />
                <input type="hidden" name="amount" value={activationFee} />
                <input type="hidden" name="productinfo" value="Elite Hub Course" />
                <input type="hidden" name="firstname" value={user?.name} />
                <input type="hidden" name="email" value={user?.email} />
                <input type="hidden" name="phone" value={user?.number} />
                <input
                  type="hidden"
                  name="surl"
                  value="https://edyguru.com/api/user/success"
                />
                <input
                  type="hidden"
                  name="furl"
                  value="https://edyguru.com/api/user/failure"
                />
                <input type="hidden" name="hash" value={hashKey} />
                <button
                  disabled={hashKey === null || transactionId === null}
                  type="submit"
                  className="pay-btn"
                >
                  {hashKey === null || transactionId === null ? (
                    <div className="d-flex align-items-center justify-content-center">
                      <span
                        className="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="ms-2">Loading...</span>
                    </div>
                  ) : (
                    `Pay ${activationFee} Now`
                  )}
                </button>
              </form> */}
            </div>
            <div className="hr-line">
              <hr className="mb-4 " />
            </div>
            <div className="activate-f-content mt-0 ">
              <h3 className="mb-3">What you will get?</h3>
              <div className="what-you-will-get">
                <div className="activate-g-content w-auto mt-0">
                  <span className="mb-3 text-light">
                    <img
                      className="me-2"
                      width="20px"
                      src={IMAGES.cashOff}
                      alt=""
                    />
                    The Elite Hub Course
                  </span>
                  <span className="mb-3 text-light">
                    <img
                      className="me-2"
                      width="20px"
                      src={IMAGES.passIncome}
                      alt=""
                    />
                    You can generate Passive Income
                  </span>
                  <span className="mb-3 text-light">
                    <img
                      className="me-2"
                      width="20px"
                      src={IMAGES.disCoupon2}
                      alt=""
                    />
                    Will provide you special offer
                  </span>
                  <span className="mb-3 text-light">
                    <img
                      className="me-2"
                      width="20px"
                      src={IMAGES.affiliateLink}
                      alt=""
                    />
                    You will get an Affiliate Link
                  </span>
                  <span className="mb-3 text-light">
                    <img
                      className="me-2"
                      width="20px"
                      src={IMAGES.coin}
                      alt=""
                    />
                    Earn money and redeemed
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* activate benefits  */}
        <div className="activate-benefits container">
          <div className="activate-content mx-2">
            <h4 className="fw-bold mb-3">Elite Hub Activation Benefits</h4>
            <p className="text-muted me-4 mb-4">
              This is a subscription model for all the affiliates of Elite Hub
              who are willing to take up the full advantage of their learning &
              practice.
            </p>
            <div className="activate-features">
              <div className="d-flex w-100">
                <img
                  width="30px"
                  height="30px"
                  className="me-3"
                  src={IMAGES.cashOff}
                  alt=""
                />
                <p className="me-4">
                  Get various offers on various elements in future and for the
                  lifetime
                </p>
              </div>
              <div className="d-flex feature-50">
                <img
                  width="30px"
                  height="30px"
                  className="me-3"
                  src={IMAGES.primeTrainer}
                  alt=""
                />
                <p>Exclusive training</p>
              </div>
              <div className="d-flex feature-50">
                <img
                  width="30px"
                  height="30px"
                  className="me-3"
                  src={IMAGES.luckyDraw}
                  alt=""
                />
                <p>Lucky draw special offer</p>
              </div>
              <div className="d-flex feature-50">
                <img
                  width="30px"
                  height="30px"
                  className="me-3"
                  src={IMAGES.offerEarly}
                  alt=""
                />
                <p>Early access to all offers</p>
              </div>
              <div className="d-flex feature-50">
                <img
                  width="30px"
                  height="30px"
                  className="me-3"
                  src={IMAGES.disCoupon}
                  alt=""
                />
                <p>Discount coupons on packages</p>
              </div>
              <div className="d-flex feature-50">
                <img
                  width="30px"
                  height="30px"
                  className="me-3"
                  src={IMAGES.passIncome}
                  alt=""
                />
                <p>Passive income daily</p>
              </div>
            </div>
          </div>
          {/* Video  */}
          <div className="activate-video">
            <img src={IMAGES.satisfaction} alt="" />
          </div>
        </div>

        {/* what u will get  */}
        <div className="what-you-will-get container">
          <h4 className="fw-bold text-center">What you will get?</h4>
          <div className="get-container">
            <div className="get-items text-center position-relative">
              <img width="50px" src={IMAGES.digitalMarketing} alt="" />
              <h6 className="mt-3">Elite Hub Course</h6>
            </div>
            <div className="get-items text-center position-relative">
              <img width="50px" src={IMAGES.affiliateLink} alt="" />
              <h6 className="mt-3">Elite Hub Affiliate Link</h6>
            </div>
            <div className="get-items text-center position-relative">
              <img width="50px" src={IMAGES.wallet} alt="" />
              <h6 className="mt-3">Digital Card</h6>
            </div>
          </div>
        </div>
      </div>
      <Faq />
    </Header>
  );
};

export default Activate;
