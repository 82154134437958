import React, { useState, useEffect } from "react";
import axios from "axios";
import "./HeroSectionTwo.css";
import { useNavigate } from "react-router-dom";

const HeroSectionTwo = () => {
  const navigate = useNavigate();
  const [banners, setBanners] = useState(null);
  const getBanners = async () => {
    try {
      const res = await axios.get("/api/banner/get-banners");
      if (res.data.success) {
        setBanners(res.data.data);
      } else {
        // Handle the case where fetching banners is not successful
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBanners();
  }, []);

  return (
    <div className="hero-main-container">
      <div className="hero-left-container">
        <h1>
          The Elites <span>Hub</span>
        </h1>
        <p>
          <small>
            Empower your future with The Elites Hub! Partnering with industry
            experts, we offer real-life skills that open doors to immense
            earning potential. Seize the opportunity to learn, grow, and secure
            a prosperous tomorrow. Your journey to success starts now!
          </small>
        </p>
        <button onClick={()=>window.location.href = '/register'}>Join Now</button>
      </div>  
      <div className="hero-right-container">
        <div className="hero-section-img-container">
          <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-indicators">
              {banners &&
                banners.map((item, index) => (
                  <button
                    key={index}
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to={index}
                    className={index === 0 ? "active" : ""}
                    aria-label={`Slide ${index + 1}`}
                  ></button>
                ))}
            </div>
            <div className="carousel-inner">
              {banners &&
                banners.map((item, index) => (
                  <div
                    key={index}
                    className={`carousel-item ${index === 0 ? "active" : ""}`}
                  >
                    <img
                      src={item.filePath}
                      className="d-block w-100"
                      alt={`Slide ${index + 1}`}
                    />
                  </div>
                ))}
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSectionTwo;
