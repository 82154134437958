import React from "react";
import { Link } from "react-router-dom";

const ReferredUsers = ({referredUser, loading}) => {
  return (
    <div className="tab">
      <div className="welcome-container">
        <div className="username">
          <span>
            <b>My Referred Users</b>
          </span>
        </div>
      </div>
      {!loading?
      <table className="table">
        <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Phone</th>
            <th>Status</th>
            {/* <th>Course Details</th> */}
          </tr>
        </thead>
        <tbody>
          {
            referredUser?.map((data, index)=>{
              return <tr key={data?._id}>
                <td data-label="Id"><strong>{index + 1}</strong></td>
                <td data-label="Name">{data?.name}</td>
                <td data-label="Phone">{data?.number}</td>
                <td data-label="Status" className={`${!data?.active && 'text-danger'}`}>{data?.active? 'Active' : 'Not Active'}</td>
                {/* <td data-label="Course"><Link to={`/records/${data?.email}`}>View</Link></td> */}
              </tr>
            })
          }
        </tbody>
      </table>
      :
      <div className="d-flex align-items-center justify-content-center mt-4">
        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
        <span className="ms-2">Loading...</span>
      </div>
      }
    </div>
  );
};

export default ReferredUsers;
