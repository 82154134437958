import "./App.css";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useParams,
} from "react-router-dom";
import Service from "./pages/Service";
import Register from "./pages/Register";
import Login from "./pages/Login";
import ForgotPass from "./pages/ForgotPass";
import Dashboard from "./user/Dashboard";
import Activate from "./user/Activate";
import UpdatePass from "./pages/UpdatePass";
import ConfirmOtp from "./pages/ConfirmOtp";
import Profile from "./user/Profile";
import MyCourse from "./user/MyCourse";
import ProtectedRoute from "./user/ProtectedRoute";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { SetUser } from "./redux/features/userSlice";
import { SetLogin } from "./redux/features/loginSlice";
import Failure from "./user/payment/Failure";
import Success from "./user/payment/Success";
import ReferralLink from "./user/ReferralLink";
import Leaderboard from "./user/Leaderboard";
import Wallet from "./user/Wallet";
import ComisionStructure from "./user/ComisionStructure";
import AccountRecords from "./user/AccountRecords";
import PaymentMethod from "./user/PaymentMethod";
import CourseInfo from "./user/CourseInfo";
import Course from "./user/Course";
import Admin from "./admin/pages/Admin";
import AdminCourse from "./admin/pages/AdminCourse";
import AdminDashboard from "./admin/pages/AdminDashboard";
import FaqPage from "./pages/FaqPage";
import CourseDetail from "./pages/CourseDetail";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Refund from "./pages/Refund";
import Disclaimer from "./pages/Disclaimer";
import AdminProtectedRoute from "./admin/pages/AdminProtectedRoute";
import AllCourse from "./components/Course/AllCourse";
import SingleCourseDetails from "./components/Course/SingleCourseDetails";
import AllUsers from "./admin/pages/AllUsers";
import Payments from "./admin/pages/Payments";
import AddBanner from "./admin/pages/AddBanner";
import AdminWithdraw from "./admin/pages/AdminWithdraw";
import AdminQueries from "./admin/pages/AdminQueries";

function App() {
  const location = useLocation();
  const id = new URLSearchParams(location.search).get('id');  
  const dispatch = useDispatch();
  const [login, setLogin] = useState(false);

  useEffect(() => {
    axios({
      method: "get",
      withCredentials: true,
      url: `api/user/get-user`,
    })
      .then((res) => {
        if (res.data.success) {
          dispatch(SetUser(res.data.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    axios({
      method: "get",
      withCredentials: true,
      url: `api/user/isLogin`,
    })
      .then((res) => {
        if (res.data.success) {
          setLogin(true);
          dispatch(SetLogin(true));
        }
      })
      .catch((err) => {
        dispatch(SetLogin(false));
        setLogin(false);
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);

  return (
      <Routes>
        {/* Pages */}
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/all-course" element={<AllCourse />} />
        <Route
          exact
          path="/edyguru-course/:name"
          element={<SingleCourseDetails />}
        />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/faqpage" element={<FaqPage />} />
        {/* <Route exact path="/service" element={<Service />} /> */}
        <Route exact path="/course-detail/:id?" element={<CourseDetail />} />
        <Route exact path="/register/:refCode?" element={<Register />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/forgotPass" element={<ForgotPass />} />
        <Route exact path="/otp" element={<ConfirmOtp />} />
        <Route exact path="/updatePass" element={<UpdatePass />} />
        <Route exact path="/terms-and-conditions" element={<Terms />} />
        <Route exact path="/privacy-policy" element={<Privacy />} />
        <Route exact path="/refund-policy" element={<Refund />} />
        <Route exact path="/disclaimer" element={<Disclaimer />} />
        {/* Payment Pages  */}
        <Route exact path="/failure/:id" element={<Failure />} />
        <Route exact path="/success/:id" element={<Success />} />
        {/* User Pages  */}
        <Route
          exact
          path="/dashboard"
          element={
            <ProtectedRoute value="/dashboard">
              <Dashboard />
            </ProtectedRoute>
          }
        />
        {/* Refferral Link  */}
        <Route
          exact
          path="/referlink"
          element={
            <ProtectedRoute value="/referlink">
              <ReferralLink />
            </ProtectedRoute>
          }
        />
        {/* Leaderboard  */}
        {/* <Route
          exact
          path="/leaderboard"
          element={
            <ProtectedRoute value="/leaderboard">
              <Leaderboard />
            </ProtectedRoute>
          }
        /> */}
        {/* Wallet  */}
        <Route
          exact
          path="/wallet/:value?"
          element={
            <ProtectedRoute value="/wallet">
              <Wallet />
            </ProtectedRoute>
          }
        />
        {/* Comission Sturcture  */}
        <Route
          exact
          path="/commisionstructure"
          element={
            <ProtectedRoute value="/commisionstructure">
              <ComisionStructure />
            </ProtectedRoute>
          }
        />
        {/* Acconunt Records  */}
        <Route
          exact
          path="/records/:tab?"
          element={
            <ProtectedRoute value="/records">
              <AccountRecords />
            </ProtectedRoute>
          }
        />
        {/* Payment Methods  */}
        <Route
          exact
          path="/payment"
          element={
            <ProtectedRoute value="/payment">
              <PaymentMethod />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/activate"
          element={
            <ProtectedRoute value="/activate">
              <Activate />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/mycourse"
          element={
            <ProtectedRoute value="/mycourse">
              <MyCourse />
            </ProtectedRoute>
          }
        />{" "}
        <Route
          exact
          path="/course/:courseName"
          element={
            <ProtectedRoute value={`/mycourse`}>
              <Course />
            </ProtectedRoute>
          }
        />{" "}
        <Route
          exact
          path="/courseInfo/:courseName"
          element={
            <ProtectedRoute>
              <CourseInfo />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/profile"
          element={
            <ProtectedRoute value="/profile">
              <Profile />
            </ProtectedRoute>
          }
        />







        {/* ========ADMIN PART ++++++++++++ */}


        <Route
          exact
          path="/admin-dashboard"
          element={
            <AdminProtectedRoute route="/admin-dashboard">
              <AdminDashboard />
            </AdminProtectedRoute>
          }
        />
        <Route
          exact
          path="/admin-course"
          element={
            <AdminProtectedRoute route="/admin-course">
              <Admin />
            </AdminProtectedRoute>
          }
        />
        <Route
          exact
          path="/admin-users"
          element={
            <AdminProtectedRoute route={`${id? `/admin-users?id=${id}`: '/admin-users'}`}>
              <AllUsers />
            </AdminProtectedRoute>
          }
        />
        <Route
          exact
          path="/admin-course/:id"
          element={
            <AdminProtectedRoute route="/admin-course">
              <AdminCourse />
            </AdminProtectedRoute>
          }
        />
        <Route
          exact
          path="/admin-payments"
          element={
            <AdminProtectedRoute route="/admin-payments">
              <Payments />
            </AdminProtectedRoute>
          }
        />
        <Route
          exact
          path="/admin-withdraw"
          element={
            <AdminProtectedRoute route="/admin-withdraw">
              <AdminWithdraw />
            </AdminProtectedRoute>
          }
        />
        <Route
          exact
          path="/admin-add-banner"
          element={
            <AdminProtectedRoute route="/admin-payments">
              <AddBanner />
            </AdminProtectedRoute>
          }
        />
        <Route
          exact
          path="/admin-queries"
          element={
            <AdminProtectedRoute route="/admin-payments">
              <AdminQueries />
            </AdminProtectedRoute>
          }
        />
      </Routes>
  );
}

export default App;
