import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { SetLogin } from '../redux/features/loginSlice';
import { useDispatch } from 'react-redux';

const ProtectedRoute = (props) => {
    const dispatch = useDispatch();
    const params = useParams()
    const navigate = useNavigate();
    const [isLogin, setIsLogin] = useState(false);

    useEffect(()=>{
        axios({
            method: "get",
            withCredentials: true,
            url: `api/user/isLogin`,
        }).then((res)=>{
            if(res.data.success){
                dispatch(SetLogin(true));
                setIsLogin(true);
                axios({
                    method: "get",
                    withCredentials: true,
                    url: `api/user/get-user`,
                }).then((res)=>{
                    if(res.data.success){
                      if(!res.data.data?.active){
                        return navigate('/activate');
                      } else if(props?.value === '/activate'){
                        return navigate('/dashboard');
                      } else if(props?.value){
                          if(params.value){
                            navigate(props?.value + '/' + params.value);
                          } else {
                            navigate(props?.value);
                          }
                      } else {
                        navigate('/dashboard')
                      }
                    }
                }).catch((err)=>{
                    console.log(err);
                }); 
            }
        }).catch((err)=>{
            navigate('/login');
            setIsLogin(false)
            dispatch(SetLogin(false));
            console.log(err);
        }); 
        // eslint-disable-next-line
    }, [])

  return (
    <>
    {isLogin && 
    <div>
        {props.children}
    </div>
    }
    </>
  )
}

export default ProtectedRoute