import React, { useEffect, useState } from "react";
import Header from "../components/Header/Header";
import SchoolIcon from "@mui/icons-material/School";
import PersonIcon from "@mui/icons-material/Person";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ReportIcon from "@mui/icons-material/Report";
import InfoIcon from "@mui/icons-material/Info";
import "./Dashboard.css";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import IMAGES from "../img";
import Layout from "./layout/Layout";
import axios from "axios";

const Dashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector((state) => state.user);
  const [referredUser, setReferredUser] = useState(null);

  function firstLCapital(string) {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  }

  const getAllReferredUser = () => {
    axios({
      method: "POST",
      data: {
        inviteCode: user?.inviteCode,
      },
      withCredentials: true,
      url: `api/user/get-all-referred-user`,
    })
      .then((res) => {
        if (res.data.success) {
          setReferredUser(res.data.data.reverse());
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (user) {
      getAllReferredUser();
    }
    // eslint-disable-next-line
  }, [user]);
  return (
    <Header>
      <Layout>
        <div className="dashboard-container">
          {/* welcome container  */}
          <div className="welcome-container">
            <h5>Hi {firstLCapital(user?.name)}</h5>
            <hr />
            <div className="username">
              <span>
                <b>Welcome</b> to The Elites Hub
              </span>
            </div>
          </div>
          {/* earning cards  */}
          <div className="d-flex flex-wrap gap-2 justify-content-between">
            <div
              className="dashboard-card"
              onClick={() => navigate("/records/referral")}
            >
              <div className="icon-cont">
                <PersonIcon className="icon" />
              </div>
              <div className="d-content text-start">
                <p className="m-0">Referrals</p>
                <h3>
                  <b>{referredUser?.length}</b>
                </h3>
              </div>
            </div>
            <div
              className="dashboard-card"
              onClick={() => {
                navigate("/wallet");
              }}
            >
              <div className="icon-cont">
                <CurrencyRupeeIcon className="icon" />
              </div>
              <div className="d-content text-start">
                <p className="m-0">Total Earnings</p>
                <h3>
                  <b>{user?.withdrawAmount}</b>
                </h3>
              </div>
            </div>
            <div
              className="dashboard-card"
              onClick={() => {
                navigate("/wallet");
              }}
            >
              <div className="icon-cont">
                <AccountBalanceWalletIcon className="icon" />
              </div>
              <div className="d-content text-start">
                <p className="m-0">Balance</p>
                <h3>
                  <b>{user?.amount}</b>
                </h3>
              </div>
            </div>
            <div
              className="dashboard-card"
              onClick={() => {
                navigate("/mycourse");
              }}
            >
              <div className="icon-cont">
                <SchoolIcon className="icon" />
              </div>
              <div className="d-content text-start">
                <p className="m-0">My Courses</p>
                <h3>
                  <b>{user?.course?.length}</b>
                </h3>
              </div>
            </div>
          </div>
          {/* profile */}
          <div className="profile-sales">
            <div className="profile text-center">
              {user?.photo ? (
                <img
                  className="userImage"
                  src={`/profileImages/${user?.photo}`}
                  alt=""
                />
              ) : (
                <img className="userImage" src="#" alt="" />
              )}
              <p className="text-center m-0 fs-4">
                <strong>{firstLCapital(user?.name)}</strong>
              </p>
              <p className="text-muted text-center">{user?.email}</p>
              <div className="wallet-container">
                <div className="wallet-card">
                  <div className="text-white d-flex justify-content-end">
                    <span>wallet</span>
                    <MoreVertIcon className="icon" />
                  </div>
                  <div className="instant-money text-start text-white">
                    <h6>Instant Money</h6>
                    <img src={IMAGES.chip} alt="" />
                    <h6 className="mt-3">
                      Redeemed Amount <RemoveRedEyeIcon />
                    </h6>
                    <h4>
                      <b>₹ {user?.earning}</b>
                    </h4>
                    <div className="card-name-logo">
                      <div className="name">
                        <span>
                          {user?.inviteCode} <InfoIcon />
                        </span>
                        <br />
                        <p className="mt-2 fw-bold fs-6">{user?.name}</p>
                      </div>
                      {/* <div className="bblogo">
                        <img width="200px" src={IMAGES.walletbb} alt="" />
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="sales">
              <h5>Recent Sales</h5>
              {referredUser?.length !== 0 ? (
                <div className="recent-sales">
                  {referredUser?.map((user, index) => {
                    return (
                      <div className="sale py-2">
                        <p className="m-0 recent-sale-index fw-bold">
                          {index + 1}
                        </p>
                        <p className="recent-sale-name m-0">
                          {firstLCapital(user?.name)}
                        </p>
                        <p className="recent-sale-number m-0">
                          <a href={`tel:${user?.number}`} className="text-dark">
                            {user?.number}
                          </a>
                        </p>
                        <p>
                          <strong
                            className={`${
                              user?.active
                                ? "text-success recent-sale-status m-0"
                                : "text-danger recent-sale-status m-0"
                            }`}
                          >
                            {user?.active ? "Active" : "Inactive"}
                          </strong>
                        </p>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="text-center mt-5 pt-5 d-flex justify-content-center align-items-center">
                  <ReportIcon />
                  <span>'No record found'</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </Header>
  );
};

export default Dashboard;
