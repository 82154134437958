import React from "react";
import { useNavigate } from "react-router-dom";
import "./allcourse.css";
import IMAGES from "../../img";
import Header from "../Header/Header";

const AllCourse = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Header>
        <div className="all-course-container p-container container text-center">
          <h1>
            What do we <span>Teach?</span>
          </h1>
          <div className="all-course-image">
            <img src={IMAGES.c1} alt="" />
            <img src={IMAGES.c2} alt="" />
            <img src={IMAGES.c3} alt="" />
            <img src={IMAGES.c4} alt="" />
            <img src={IMAGES.c5} alt="" />
            <img src={IMAGES.c6} alt="" />
            <img src={IMAGES.c7} alt="" />
            <img src={IMAGES.c8} alt="" />
            <img src={IMAGES.c9} alt="" />
            <img src={IMAGES.c10} alt="" />
            <img src={IMAGES.c11} alt="" />
            <img src={IMAGES.c12} alt="" />
            <img src={IMAGES.c13} alt="" />
            <img src={IMAGES.c14} alt="" />
            <img src={IMAGES.c15} alt="" />
            <img src={IMAGES.c16} alt="" />
            <img src={IMAGES.c17} alt="" />
            <img src={IMAGES.c18} alt="" />
            <img src={IMAGES.b1} alt="" />
            <img src={IMAGES.b2} alt="" />
          </div>
        </div>
      </Header>
    </React.Fragment>
  );
};

export default AllCourse;
