import { createSlice } from "@reduxjs/toolkit";

export const videoSlice = createSlice({
    name: "allCourseVideos",
    initialState: {
        allCourseVideos: null
    },
    reducers: {
        SetAllCourseVideos: (state, action) => {
            state.allCourseVideos = action.payload;
          },
    }
})

export const { SetAllCourseVideos } = videoSlice.actions;

export default videoSlice.reducer;