import React, { useEffect, useState } from "react";
import Header from "../components/Header/Header";
import "./MyCourse.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Layout from "./layout/Layout";
import CourseHistory from "./components/CourseHistory";

const MyCourse = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);

  const [courses, setCourses] = useState(null);

  return (
    <Header>
      <Layout>
        <CourseHistory heading='My Course'/>
      </Layout>
    </Header>
  );
};

export default MyCourse;
