import { createSlice } from "@reduxjs/toolkit";

export const adminSlice = createSlice({
  name: "login",
  initialState: {
    admin: false,
  },
  reducers: {
    SetAdmin: (state, action) => {
      state.admin = action.payload;
    },
  },
});

export const { SetAdmin } = adminSlice.actions;

export default adminSlice.reducer;
