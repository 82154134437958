import React from "react";
import "./AdminSideMenu.css";
import { Link, useLocation } from "react-router-dom";
import AdminMenu from "./AdminMenu";

const AdminSideMenu = ({ adminSideMenu, setAdminSideMenu }) => {
  const location = useLocation();
  return (
    <div
      className={`admin-sidemenu-container ${adminSideMenu ? "active" : ""}`}
    >
      <div className="admin-sidemenu">
        <ul>
          {AdminMenu?.map((item, index) => {
            const IconComponent = item.icon;
            return (
              <li
                className={`${
                  location.pathname === `${item.path}` ? "active" : ""
                }`}
              >
                <IconComponent />
                <Link className="ms-2" to={item.path}>
                  {item.name}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default AdminSideMenu;
