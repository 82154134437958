import React, { useEffect, useState } from "react";
import Header from "../components/Header/Header";
import Layout from "./layout/Layout";
import LockIcon from '@mui/icons-material/Lock';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "./CourseInfo.css";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const CourseInfo = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [singleCourse, setSingleCourse] = useState(null);
  const { user } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);

  const getSingleCourse = async ()=>{
    axios({
      method: "POST",
      data: {
        courseName: params?.courseName
      },
      withCredentials: true,
      url: `api/course/get-course-by-name`,
    })
      .then((res) => {
        setSingleCourse(res.data.data);
      })  
      .catch((err) => {
        console.log(err);
      });
  };

  function capitalizeFirstLetter(str) {
    if (typeof str !== 'string' || str.length === 0) {
      return str;
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const unlockCourse = ()=>{
    axios({
      method: "POST",
      data: {
        id: user?._id,
        courseAmount: singleCourse?.price,
        availableAmount: user?.amount,
        courseName: singleCourse?.courseName,
      },
      withCredentials: true,
      url: `api/user/set-unlock-course-user-price`,
    })
      .then((res) => {
        if(res.data.success){
          setMessage(true)
        }
      })  
      .catch((err) => {
        console.log(err);
      });
  }


  useEffect(()=>{
    getSingleCourse();
    // eslint-disable-next-line
  }, []);
  return (
    <Header>
      <Layout>
        <div className="dashboard-container">
          {/* welcome container  */}
          <div className="welcome-container">
            <h5><ArrowBackIosIcon className="icon closeIcon" onClick={()=>{navigate('/mycourse')}}/> {capitalizeFirstLetter(singleCourse?.courseName)}Course Details</h5>
            <hr />
            <div className="username">
              <span>
                "Knowledge is the compass, earnings are the destination. First,
                navigate your mind, then set sail towards success." First{" "}
              </span>
            </div>
          </div>
          <div className="course-detail-container">
            {singleCourse? <div className="course-detail-img position-relative">
              <img src={`/courseImages/${singleCourse?.image}`} alt="" />
             {singleCourse?.image && <LockIcon className="fs-4 text-warning m-2 position-absolute top-0 start-0 m-3"/>}
            </div>
            :
            <div className="d-flex">
              <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
              <span className="ms-2">Loading...</span>
            </div>
            }
            <div className="course-detail-content">
              <h4>What you will Learn?</h4>
              <ul>
                <li>How to purchase Domain</li>
                <li>How to purchase Hosting</li>
                <li>How to connect domain and hosting</li>
                <li>How to install wordpress on our server</li>
                <li>Basic Wordpress setup</li>
                <li>Install Wordpress Theme</li>
                <li>Learn about Elementor</li>
              </ul>
              <button className="course-detail-btn" data-bs-toggle="modal" data-bs-target="#ffweefwefewf">Unlock Now</button>
            </div>
          </div>
        </div>
        {/* unlock course modal */}
        <div className="modal fade" id="ffweefwefewf" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
            <div className="modal-content rounded-0 pt-2">
              <div className="modal-body">
                {singleCourse?.price >= user?.amount? <div>
                  <p>Course Amount: {singleCourse?.price} Coin</p>
                  <p>Available Amount: {user?.amount} Coin</p>
                  <p className="text-white bg-danger py-1 px-2">Sorry you don't have enough coin for unlock this course.</p>
                  <p className="text-white bg-danger py-1 px-2">You need {singleCourse?.price - user?.amount} more coin to unlock this course.</p>
                </div>
                :
                <div>
                  {!message? 
                  <p className="text-white bg-success py-1 px-2">After unlock {singleCourse?.courseName} course you will have {user?.amount - singleCourse?.price} coins left.</p>
                  :
                  <p className="text-white bg-success py-1 px-2">Unlocked {capitalizeFirstLetter(singleCourse?.courseName)} Course</p>
                  }
                </div>
                }
              </div>
              <div className="modal-footer">
                {message?
                <button type="button" className="btn btn-secondary rounded-0" data-bs-dismiss="modal" onClick={()=>{navigate('/mycourse')}}>Close</button>
                :
                <button type="button" className="btn btn-secondary rounded-0" data-bs-dismiss="modal">Cancle</button>
                }
                {singleCourse?.price > user?.amount?
                <button type="button" className="btn btn-warning rounded-0" data-bs-dismiss="modal" onClick={()=>{navigate(`/wallet/${singleCourse?.price}`)}}>{`Add ${singleCourse?.price - user?.amount} Coin`} </button>
                :
                <>
                {!message && <button type="button" className="btn btn-warning rounded-0" onClick={unlockCourse}>Confirm </button>}
                </>
                }
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </Header>
  );
};

export default CourseInfo;
