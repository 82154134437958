import React, { useEffect, useState } from "react";
import "./Contact.css";
import Header from "../components/Header/Header";
import IMAGES from "../img";
import axios from "axios";

const Contact = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [form, setForm] = useState({ name: "", email: "", message: "" });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios({
      method: "POST",
      data: {
        ...form,
      },
      withCredentials: true,
      url: `api/user/add-inquiry`,
    })
      .then((res) => {
        if (res.data.success) {
          setTimeout(() => {
            setLoading(false);
            setMessage("Inquiry has been sent, you will get a reply soon.");
          }, 1500);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  useEffect(() => {
    if (message) {
      setTimeout(() => {
        setMessage(null);
      }, 5000);
    }
  }, [message]);

  return (
    <Header>
      <div className="container-fluid contact-container">
        <div className="row">
          <div className="contact-img-div text-center col-12 col-sm-12 col-md-6 col-lg-6">
            <img
              className="contact-img mb-3 "
              src={IMAGES.contact3}
              loading="lazy"
              alt=""
            />
            <div className="contact-us-text text-white">
              <i className="fa-solid fa-at"></i>
              Contact Us
              <p className="fs-6 p-0 m-0 mt-3">
                We are available Monday to Friday from 10am to 6pm.
              </p>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 px-0">
            <div className="contact-form">
              <form onSubmit={handleSubmit}>
                <h5 className="mb-4 position-relative line">
                  Feel free to message us!
                </h5>
                <p>
                  Let our expert team handle all your concerns and give you a
                  quick demo of our product & services
                </p>
                <hr />
                {/* Name  */}
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Your Name
                  </label>
                  <input
                    name="name"
                    type="text"
                    className="form-control"
                    required
                    value={form?.name}
                    placeholder="Enter you name"
                    onChange={handleChange}
                  />
                </div>{" "}
                {/* Name  */}
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Your Email
                  </label>
                  <input
                    name="email"
                    type="email"
                    className="form-control"
                    required
                    value={form?.email}
                    placeholder="Enter you email"
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Your Message
                  </label>
                  <textarea
                    name="message"
                    type="text"
                    className="form-control"
                    placeholder="Leave a comment here"
                    required
                    value={form?.message}
                    rows="5"
                    onChange={handleChange}
                  ></textarea>
                </div>
                <div className="col-auto mb-3">
                  <span
                    id="passwordHelpInline"
                    className="form-text text-danger"
                  >
                    {message}
                  </span>
                </div>
                <button
                  type="submit"
                  className="btn btn-dark bg-dark w-100 py-3"
                >
                  {loading ? (
                    <div className="d-flex align-items-center justify-content-center">
                      <span
                        className="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="ms-2">Loading...</span>
                    </div>
                  ) : (
                    "Submit"
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid contact-address-email">
        <a
          className="text-center c-address shadow m-3"
          href="https://www.google.com/maps/search/?q=Thane"
          style={{ textDecoration: "none", color: "black" }}
        >
          <i
            className="fa-solid fa-location-dot mb-2 text-dark"
            style={{ fontSize: "45px" }}
          ></i>
          <p>
            <b>Mumbai Thane</b>
          </p>
        </a>
        <a
          className="text-center c-address shadow m-3"
          href="mailto:contact@edyguru.com"
          style={{ textDecoration: "none", color: "black" }}
        >
          <i
            className="fa-solid fa-envelope mb-2 text-dark"
            style={{ fontSize: "45px" }}
          ></i>
          <p>
            <b>contact@theeliteshub.com</b>
          </p>
        </a>
        <a
          className="text-center c-address shadow m-3"
          href="https://wa.me/917876939551"
          style={{ textDecoration: "none", color: "black" }}
        >
          <i
            className="fa-brands fa-whatsapp mb-2 text-dark"
            style={{ fontSize: "45px" }}
          ></i>
          <p>
            <b>WhatsApp</b>
          </p>
        </a>
      </div>
    </Header>
  );
};

export default Contact;
